import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Formik } from "formik";
import CustomDialog from "../../../common/CustomDialog";
import {
  GroupCauseValidationSchema,
  GroupNameValidationSchema,
} from "../../../../constants/validate";
import CustomInput from "../../../common/CustomInput";
import Spinner from "../../../common/Spinner";
import CustomButton from "../../../common/CustomButton";

const GroupNameModal = ({
  groupData = null,
  onSubmit,
  submitText = "Submit",
  loader,
  open,
  onClose,
  title,
}) => {
  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const initialValue = groupData?.groupName
    ? { groupName: groupData.groupName }
    : { groupName: "" };

  return (
    <CustomDialog open={open} onClose={onClose} title={title}>
      <div className="cause-modal">
        <Formik
          initialValues={initialValue}
          onSubmit={handleSubmit}
          validationSchema={GroupNameValidationSchema}
        >
          {({ handleSubmit, setFieldValue, errors, values, setValues }) => (
            <Form>
              <div className="combined-cause">
                <CustomInput
                  className={"custom-input"}
                  label={"Group Name"}
                  name={"groupName"}
                  placeholder="Group xyz"
                ></CustomInput>
              </div>
              {loader ? (
                <Spinner />
              ) : (
                <CustomButton
                  className={"custom-button"}
                  title={submitText}
                  onClick={handleSubmit}
                ></CustomButton>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
};

export default GroupNameModal;
