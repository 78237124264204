import React, { useState } from "react";
import "./styles.scss";
import CustomTable from "../../../common/CustomTable";
import "react-datepicker/dist/react-datepicker.css";
import CustomButton from "../../../common/CustomButton";
import CustomDialog from "../../../common/CustomDialog";
import Spinner from "../../../common/Spinner";

const FundraiserSelectorModal = ({
  fundraiserData,
  open,
  onClose,
  loader,
  onSubmit,
  title = "Fundraiser With Similar Name or Address",
}) => {
  const [selection, setSelection] = useState(null);

  const onSelection = (index) => {
    setSelection(index);
  };

  const fundraisersRows = fundraiserData || [];
  const fundraisersColumn = [
    { name: "Name", dataIndex: "fundRaisingName" },
    { name: "Address", dataIndex: "address" },
  ];

  return (
    <CustomDialog open={open} onClose={onClose} title={title}>
      <div className="similar-fundraisers-modal">
        <CustomTable
          columns={fundraisersColumn}
          rows={fundraisersRows}
          isSelection={true}
          onSelect={onSelection}
          maxHeight={300}
        />
        <div className="button-group">
          {loader ? (
            <Spinner />
          ) : (
            <CustomButton
              title={"Select"}
              disabled={selection === null}
              onClick={() => onSubmit(fundraiserData[selection]?._id)}
            ></CustomButton>
          )}
        </div>
      </div>
    </CustomDialog>
  );
};

export default FundraiserSelectorModal;
