import React from "react";
import ".././styles.scss";
import CustomInput from "../../../../common/CustomInput";
import { Form, Formik } from "formik";
import CustomButton from "../../../../common/CustomButton";
import { newParticipantValidationSchema } from "../../../../../constants/validate";
import "react-datepicker/dist/react-datepicker.css";
import CustomDialog from "../../../../common/CustomDialog";
import Spinner from "../../../../common/Spinner";
import CustomSelect from "../../../../common/CustomSelect";
import CustomPhone from "../../../../common/CustomPhone";

const InviteParticipantModal = ({
  onSubmit,
  loader,
  open,
  onClose,
  groups = null,
}) => {
  const handleFormSubmit = (values, actions) => {
    onSubmit(values);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  };

  const groupsList =
    groups &&
    [
      {
        value: null,
        label: "unassigned",
      },
    ].concat([
      ...groups.map((group) => {
        return {
          value: group?._id,
          label: group?.groupName,
        };
      }),
    ]);

  return (
    <CustomDialog open={open} onClose={onClose} title={"New Participant"}>
      <div className="invite-participant-modal">
        <div className="info-modal-wrapper">
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
            validationSchema={newParticipantValidationSchema}
          >
            {({ handleSubmit, setFieldValue, errors, values, setValues }) => (
              <Form>
                <div className="form-section">
                  <CustomInput
                    label={"First Name"}
                    name="firstName"
                    className={"input"}
                    placeholder={"Bob"}
                  />
                  <CustomInput
                    label={"Last Name"}
                    name="lastName"
                    className={"input"}
                    placeholder={"Smith"}
                  />
                  <CustomInput
                    label={"Email Address"}
                    name="email"
                    className={"input"}
                    placeholder={"BobSmith123@gmail.com"}
                  />
                  <CustomPhone
                    label={"Phone (optional)"}
                    name="phoneNumber"
                    className={"input"}
                    placeholder={"XXXX-XXXX-X"}
                  />
                  {groups && (
                    <CustomSelect
                      label={"Group"}
                      name="group"
                      placeholder="Select group"
                      options={groupsList}
                      onChange={(event) =>
                        setValues({ ...values, group: event.target.value })
                      }
                    />
                  )}

                  <div className="button-group">
                    {loader ? (
                      <Spinner />
                    ) : (
                      <CustomButton
                        title={"Confirm"}
                        onClick={handleSubmit}
                        className={"submit-button"}
                        disabled={Object.keys(errors).length > 0}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </CustomDialog>
  );
};

export default InviteParticipantModal;
