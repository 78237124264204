import React, { useEffect, useState } from "react";
import "./styles.scss";
import CustomTable from "../../../common/CustomTable";
import { getApprovedAdmins, updatePrivileges } from "../../../../apis/Admin";
import PermissionModal from "./PermissionModal";
import { Toaster } from "../../../../constants";

const ApprovedAdmins = ({ isGetAdminApprovals }) => {
  const pendingApprovalsColumn = [
    { name: "First Name", dataIndex: "firstName" },
    { name: "Last Name", dataIndex: "lastName" },
    { name: "Email", dataIndex: "email" },
    { name: "Phone Number", dataIndex: "phone" },
  ];

  const [loader, setLoader] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [openPermissionModal, setOpenPermissionModal] = useState(false);

  const handleClosePermissionModal = () => {
    setOpenPermissionModal(false);
    setSelectedAdmin(null);
  };

  const handleEdit = (data) => {
    setSelectedAdmin(data);
    setOpenPermissionModal(true);
  };

  const handleGetAllApprovedAdmins = async () => {
    try {
      setLoader(true);
      let response = await getApprovedAdmins();
      let tempArray = response?.data;
      tempArray =
        tempArray.length === 0
          ? []
          : tempArray.map((item) => {
              return {
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email,
                phone: item.phoneNo,
                ...item,
              };
            });
      setAdmins(tempArray);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleEditPermission = async (values) => {
    try {
      let body = {
        adminId: selectedAdmin._id,
        privileges: values.privileges,
      };
      await updatePrivileges(body);
      await handleGetAllApprovedAdmins();
      Toaster("success", "Priveleges has been updated successfully");
    } catch (error) {
      let errorMessage =
        error.response?.data?.message || "Invite link could not be sent.";
      Toaster("error", errorMessage);
      throw new Error(errorMessage);
    }
  };

  useEffect(() => {
    handleGetAllApprovedAdmins();
  }, [isGetAdminApprovals]);

  return (
    <div className="admin-approved-section-wrapper">
      <div className="admin-sub-title">Approved Admins</div>
      <div className="admin-approval-list-wrapper">
        <CustomTable
          columns={pendingApprovalsColumn}
          loader={loader}
          rows={admins.filter((admin) => admin.role === "secondaryAdmin")}
          maxHeight={300}
          isActions
          isDelete={false}
          onEdit={handleEdit}
        />
      </div>
      <PermissionModal
        selectedData={selectedAdmin}
        open={openPermissionModal}
        onClose={handleClosePermissionModal}
        onSubmit={handleEditPermission}
      />
    </div>
  );
};

export default ApprovedAdmins;
