import React, { useRef, useState } from "react";
import ".././styles.scss";
import "react-datepicker/dist/react-datepicker.css";
import { NEW_FUNDRAISER_STAGES, Toaster } from "../../../../../constants/index";
import FundraiserInfoModal from "./FundraiserInfoModal";
import SimilarFundraisersModal from "./SimilarFundraisersModal";
import GroupsModal from "./GroupsModal";
import GoalModal from "./GoalModal";
import CauseModal from "./CauseModal";
import StripeModal from "./StripeModal";
import {
  CreateGroups,
  createFundraiser,
  fetchSimilarFundraisers,
  updateFundraiser,
  updateFundraisingStripe,
} from "../../../../../apis/Fundraiser";
import { useNavigate } from "react-router-dom";

// {
//   name: "Muhammad Anas Mansha",
//   address: "Lahore, Pakistan",
//   city: "Lahore",
//   state: "Punjab",
//   zipCode: "5400",
//   startDate: "2024-03-13",
//   endDate: "2024-04-05",
//   routingNumber: "121",
//   accountNumber: "2",
//   verifyAccountNumber: "2",
//   checks: {
//     isAuthorizedAdmin: true,
//     isPrivacyPolicy: true,
//     isTerms: true,
//   },
//   taxId: "501",
// }
const NewFundraiserModalV2 = ({
  open = false,
  onClose = () => console.log("On Close Handler"),
  openPreviousFundraiserSelector,
}) => {
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const handleFetchSimilarFundraisers = async () => {
    try {
      setLoader(true);

      const similarFundraisers = await fetchSimilarFundraisers(
        fundraiserData.current?.name
      );

      fundraiserData.current.similarFundraisers = similarFundraisers;

      setLoader(false);
      toggleNextStage();
    } catch (error) {
      let errorMessage =
        error.response?.data?.message || "Error Fetching Similar Fundraisers!";
      Toaster("error", errorMessage);
      setLoader(false);

      throw new Error(errorMessage);
    }
  };

  const handleCreateFundraiser = async () => {
    try {
      setLoader(true);
      const currentData = fundraiserData.current;
      const formData = {
        fundRaisingName: currentData.name,
        address: currentData.address,
        city: currentData.city,
        state: currentData.state,
        zip: currentData.zipCode,
        fundRaisingTaxId: currentData.taxId,
        fundRaisingRoutingNumber: currentData.routingNumber,
        fundRaisingAccountNumber: currentData.accountNumber,
        fundRaisingStartTime: new Date(currentData.startDate).getTime(),
        fundRaisingEndTime: new Date(currentData.endDate).getTime(),
      };
      const fundraisingData = await createFundraiser(formData);

      fundraiserData.current.fundraisingData = fundraisingData;

      Toaster("success", "Fundraiser has been created successfully");
      setLoader(false);
      toggleNextStage();
    } catch (error) {
      let errorMessage =
        error.response?.data?.message || "Fundraiser could not be created";
      Toaster("error", errorMessage);
      setLoader(false);

      throw new Error(errorMessage);
    }
  };

  const handleGroupsCreation = async () => {
    try {
      setLoader(true);
      const groupData = fundraiserData.current.groups?.map((group) => {
        return {
          groupName: group.name,
          adminEmail: group.admin,
          groupTarget: group.groupTarget || 0,
          groupCause: group.groupCause || "",
          fundRaisingId: fundraiserData.current.fundraisingData.data._id,
        };
      });
      const fundRaisingId = fundraiserData.current.fundraisingData.data._id;
      if (groupData?.length) {
        let groups = { groups: groupData };
        const createGroupPromise = CreateGroups(groups);

        let body = {
          fundRaisingGoal: fundraiserData.current.fundraiserGoal || 0,

          fundRaisingCause: fundraiserData.current.fundRaisingCause || "",
          fundRaisingId,
        };
        const updateFundraiserPromise = updateFundraiser(body);

        let updateStripePromise = null;

        const stripe = fundraiserData.current.fundRaisingStripe;
        if (stripe)
          updateStripePromise = updateFundraisingStripe({
            fundRaisingStripe: stripe,
            fundRaisingId,
          });

        await Promise.all(
          [
            createGroupPromise,
            updateFundraiserPromise,
            updateStripePromise,
          ].filter(Boolean)
        );

        Toaster("success", "Groups created successfully! Redirecting...");
        setTimeout(() => {
          navigate(`/admin/dashboard/${fundRaisingId}`);
        }, [3000]);
      } else {
        const currentData = fundraiserData.current;
        if (
          currentData.fundraiserGoal ||
          currentData.fundRaisingCause ||
          currentData.fundRaisingStripe
        ) {
          let updateFundraiserPromise = null;
          let updateStripePromise = null;
          if (fundRaisingId) {
            if (currentData.fundraiserGoal || currentData.fundRaisingCause) {
              const body = { fundRaisingId };
              if (currentData.fundraiserGoal)
                body.fundRaisingGoal = currentData.fundraiserGoal;
              if (currentData.fundRaisingCause)
                body.fundRaisingCause = currentData.fundRaisingCause;
              updateFundraiserPromise = updateFundraiser(body);
            }
            if (currentData.fundRaisingStripe) {
              updateStripePromise = updateFundraisingStripe({
                fundRaisingId,
                fundRaisingStripe: currentData.fundRaisingStripe,
              });
            }
            await Promise.all(
              [updateFundraiserPromise, updateStripePromise].filter(Boolean)
            );
          }
        }
        setLoader(false);
        Toaster("success", "Redirecting to fundraiser...");
        setTimeout(() => {
          navigate(`/admin/dashboard/${fundRaisingId}`);
        }, [3000]);
      }
    } catch (error) {
      Toaster("error", "Error creating groups!");
      setLoader(false);
    }
  };

  const [currentStage, setCurrentStage] = useState(
    NEW_FUNDRAISER_STAGES.FUNDRAISER_INFO
  );

  const fundraiserData = useRef(null);

  const updateFundraiserData = (data) => {
    console.log("UPDATING:", data);
    fundraiserData.current = data;
  };

  const toggleNextStage = () => {
    switch (currentStage) {
      case NEW_FUNDRAISER_STAGES.FUNDRAISER_INFO:
        setCurrentStage(NEW_FUNDRAISER_STAGES.SIMILAR_FUNDRAISERS);
        break;
      case NEW_FUNDRAISER_STAGES.SIMILAR_FUNDRAISERS:
        setCurrentStage(NEW_FUNDRAISER_STAGES.GROUPS);
        break;
      case NEW_FUNDRAISER_STAGES.GROUPS:
        setCurrentStage(NEW_FUNDRAISER_STAGES.GOAL);
        break;
      case NEW_FUNDRAISER_STAGES.GOAL:
        setCurrentStage(NEW_FUNDRAISER_STAGES.CAUSE);
        break;
      case NEW_FUNDRAISER_STAGES.CAUSE:
        setCurrentStage(NEW_FUNDRAISER_STAGES.LINK_STRIPE);
        break;
      case NEW_FUNDRAISER_STAGES.LINK_STRIPE:
        setCurrentStage(NEW_FUNDRAISER_STAGES.FUNDRAISER_INFO);
        break;
      default:
        setCurrentStage(currentStage + 1);
        break;
    }
  };

  return (
    <div>
      {currentStage === NEW_FUNDRAISER_STAGES.FUNDRAISER_INFO ? (
        <FundraiserInfoModal
          fundraiserData={fundraiserData.current}
          loader={loader}
          onSubmit={handleFetchSimilarFundraisers}
          updateFundraiserData={updateFundraiserData}
          open={open}
          onClose={onClose}
          openPreviousFundraiserSelector={openPreviousFundraiserSelector}
        ></FundraiserInfoModal>
      ) : currentStage === NEW_FUNDRAISER_STAGES.SIMILAR_FUNDRAISERS ? (
        <SimilarFundraisersModal
          fundraiserData={fundraiserData.current}
          loader={loader}
          onSubmit={handleCreateFundraiser}
          updateFundraiserData={updateFundraiserData}
          open={open}
          onClose={onClose}
          onBack={() => setCurrentStage(NEW_FUNDRAISER_STAGES.FUNDRAISER_INFO)}
        ></SimilarFundraisersModal>
      ) : currentStage === NEW_FUNDRAISER_STAGES.GROUPS ? (
        <GroupsModal
          fundraiserData={fundraiserData.current}
          loader={loader}
          onSubmit={toggleNextStage}
          updateFundraiserData={updateFundraiserData}
          open={open}
          onClose={onClose}
          title={"Create Groups"}
        ></GroupsModal>
      ) : currentStage === NEW_FUNDRAISER_STAGES.GOAL ? (
        <GoalModal
          fundraiserData={fundraiserData.current}
          loader={loader}
          onSubmit={toggleNextStage}
          updateFundraiserData={updateFundraiserData}
          open={open}
          onClose={onClose}
          title={"Goal"}
          onBack={() => setCurrentStage(NEW_FUNDRAISER_STAGES.GROUPS)}
          submitText={"Next"}
          onSkip={toggleNextStage}
        ></GoalModal>
      ) : currentStage === NEW_FUNDRAISER_STAGES.CAUSE ? (
        <CauseModal
          fundraiserData={fundraiserData.current}
          loader={loader}
          onSubmit={toggleNextStage}
          onSkip={toggleNextStage}
          updateFundraiserData={updateFundraiserData}
          open={open}
          onClose={onClose}
          title={"Cause"}
          onBack={() => setCurrentStage(NEW_FUNDRAISER_STAGES.GOAL)}
        ></CauseModal>
      ) : currentStage === NEW_FUNDRAISER_STAGES.LINK_STRIPE ? (
        <StripeModal
          fundraiserData={fundraiserData.current}
          loader={loader}
          onSubmit={handleGroupsCreation}
          updateFundraiserData={updateFundraiserData}
          open={open}
          onClose={onClose}
          onBack={() => setCurrentStage(NEW_FUNDRAISER_STAGES.CAUSE)}
        ></StripeModal>
      ) : null}
    </div>
  );
};

export default NewFundraiserModalV2;
