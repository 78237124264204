import React, { useEffect, useState } from "react";
import CustomDialog from "../../../common/CustomDialog";
import { Formik, Form } from "formik";
import CustomButton from "../../../common/CustomButton";
import CustomInput from "../../../common/CustomInput";
import Spinner from "../../../common/Spinner";
import { Grid, FormGroup, FormControlLabel } from "@mui/material";
import CustomCheckBox from "../../../common/CustomCheckBox";
import { adminPermissionValidationSchema } from "../../../../constants/validate";
import CustomPhone from "../../../common/CustomPhone";

const privileges = [
  {
    value: "startNewFundraiser",
    label: "Start new fundraiser",
  },
  {
    value: "acceptStudentFund",
    label: "Accept Student funds",
  },
  {
    value: "sendEmailAndTexts",
    label: "Send emails and texts",
  },
  {
    value: "changeGoalsAndCause",
    label: "Change goals and cause",
  },
  {
    value: "addNewAdministrator",
    label: "Add new administrator",
  },
];

const PermissionModal = ({
  open,
  onClose,
  onSubmit,
  selectedData = null,
  title = "",
}) => {
  const [loader, setLoader] = useState(false);

  const handlePrivilegesChange = (setValues, values, name, value) => {
    if (name === "fullAccess") {
      let permissions = { ...values.privileges };
      let obj = {};
      for (const key in permissions) {
        obj[key] = value;
      }
      setValues({
        ...values,
        privileges: obj,
      });
    } else {
      let permissions = { ...values.privileges, [name]: value };

      let isPermissionsAllTrue = Object.entries(permissions)
        .filter(([key, value]) => key !== "fullAccess")
        .every(([key, value]) => value === true);

      if (isPermissionsAllTrue) {
        setValues({
          ...values,
          privileges: {
            ...permissions,
            fullAccess: true,
          },
        });
      } else {
        setValues({
          ...values,
          privileges: { ...permissions, fullAccess: false },
        });
      }
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      setLoader(true);
      await onSubmit(values);
      setLoader(false);
      onClose();
      actions.setValues({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        privileges: {
          fullAccess: false,
          startNewFundraiser: false,
          acceptStudentFund: false,
          sendEmailAndTexts: false,
          changeGoalsAndCause: false,
          addNewAdministrator: false,
        },
      });
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      <CustomDialog open={open} onClose={onClose} title={title}>
        <div className="admin-permission-wrapper">
          <Formik
            initialValues={{
              firstName: selectedData ? selectedData.firstName : "",
              lastName: selectedData ? selectedData.lastName : "",
              email: selectedData ? selectedData.email : "",
              phoneNo: selectedData ? selectedData.phone : "",
              privileges: {
                fullAccess: selectedData
                  ? Object.entries(selectedData?.privileges).every(
                      ([key, value]) => value === true
                    )
                  : false,
                startNewFundraiser: selectedData
                  ? selectedData?.privileges?.startNewFundraiser
                  : false,
                acceptStudentFund: selectedData
                  ? selectedData?.privileges?.acceptStudentFund
                  : false,
                sendEmailAndTexts: selectedData
                  ? selectedData?.privileges?.sendEmailAndTexts
                  : false,
                changeGoalsAndCause: selectedData
                  ? selectedData?.privileges?.changeGoalsAndCause
                  : false,
                addNewAdministrator: selectedData
                  ? selectedData?.privileges?.addNewAdministrator
                  : false,
              },
            }}
            enableReinitialize={selectedData}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={handleSubmit}
            validationSchema={adminPermissionValidationSchema}
          >
            {({ handleSubmit, setValues, values }) => (
              <Form>
                <div className="middle-section">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <CustomInput
                        label={"First Name"}
                        type={"text"}
                        name="firstName"
                        placeholder="John"
                        disabled={selectedData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <CustomInput
                        label={"Last Name"}
                        type={"text"}
                        name="lastName"
                        placeholder="Smith"
                        disabled={selectedData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <CustomInput
                        label={"Email"}
                        type={"text"}
                        name="email"
                        placeholder="John@hotmail.com"
                        disabled={selectedData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <CustomPhone
                        label={"Phone Number"}
                        name="phoneNo"
                        className={"input"}
                        placeholder={"XXXX-XXXX-X"}
                        disabled={selectedData}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className="privilege-wrapper">
                  <div className="title">Privileges</div>
                  <div className="privilege-list">
                    <FormGroup>
                      <FormControlLabel
                        name="permissions"
                        typeof="checkbox"
                        checked={values.privileges.fullAccess}
                        onChange={(event) =>
                          handlePrivilegesChange(
                            setValues,
                            values,
                            "fullAccess",
                            event.target.checked
                          )
                        }
                        required
                        control={<CustomCheckBox />}
                        label={<p className="privilege">All Privileges</p>}
                      />
                      <div className="privilege-child">
                        {privileges.map((privilege) => (
                          <FormControlLabel
                            name={privilege.value}
                            typeof="checkbox"
                            checked={values.privileges[privilege.value]}
                            required
                            onChange={(event) =>
                              handlePrivilegesChange(
                                setValues,
                                values,
                                privilege.value,
                                event.target.checked
                              )
                            }
                            control={<CustomCheckBox />}
                            label={
                              <p className="privilege">{privilege.label}</p>
                            }
                          />
                        ))}
                      </div>
                    </FormGroup>
                  </div>
                </div>

                <div className="admin-permission-button-wrapper">
                  {loader ? (
                    <Spinner />
                  ) : (
                    <CustomButton
                      onClick={handleSubmit}
                      className={"button"}
                      title={"Confirm"}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </CustomDialog>
    </>
  );
};

export default PermissionModal;
