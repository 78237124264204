import React from "react";
import ".././styles.scss";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "../../../../common/CustomInput";
import { Form, Formik } from "formik";
import CustomButton from "../../../../common/CustomButton";
import { ManualDonationValidationSchema } from "../../../../../constants/validate";
import CustomDialog from "../../../../common/CustomDialog";
import Spinner from "../../../../common/Spinner";

const ManualDonationModal = ({ onSubmit, loader, open, onClose }) => {
  const initialValue = { amount: 0, comment: "" };

  return (
    <CustomDialog open={open} onClose={onClose} title={"Add funds manually"}>
      <div className="goal-modal">
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={ManualDonationValidationSchema}
        >
          {({ handleSubmit }) => (
            <Form>
              <CustomInput
                className={"custom-input"}
                type={"number"}
                step={"100"}
                min={"0"}
                label={"Donation Amount"}
                name={"amount"}
                placeholder="$100,000"
              ></CustomInput>
              <CustomInput
                className={"custom-input spacing"}
                label={"Comments (optional)"}
                name={"comments"}
                placeholder="Paid in cash"
              ></CustomInput>
              {loader ? (
                <Spinner />
              ) : (
                <CustomButton
                  className={"custom-button"}
                  title={"Submit"}
                  onClick={handleSubmit}
                ></CustomButton>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
};

export default ManualDonationModal;
