import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  me: null,
};

export const authSlice = createSlice({
  name: "me",
  initialState,
  reducers: {
    getAuth: (state, actions) => {
      state.me = actions.payload;
    },
    clearAuth: (state, actions) => {
      state.me = null;
      localStorage.removeItem("fundToken");
      localStorage.removeItem("userDetails");
    },
  },
});

// Action creators are generated for each case reducer function
export const { getAuth, clearAuth } = authSlice.actions;

export default authSlice.reducer;
