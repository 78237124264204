import { Checkbox } from "@mui/material";
import React from "react";

const CustomCheckBox = ({ checked, title, className, ...props }) => {
  return (
    <div style={{ display: "flex" }} className={`${className}`}>
      <Checkbox
        checked={Boolean(checked)}
        {...props}
        sx={{
          color: "#E9C451",
          "&.Mui-checked": {
            color: "#E9C451",
          },
        }}
      />
      <div style={{ fontSize: 14 }}>{title}</div>
    </div>
  );
};

export default CustomCheckBox;
