import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomDialog from "../../../common/CustomDialog";
import CustomButton from "../../../common/CustomButton";
import { Toaster } from "../../../../constants";

const TeammemberLinkModal = ({ open, onClose, invitationLink }) => {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={"Teammember Invitation Link"}
    >
      <div className="link-modal">
        <div className="info">
          Use this link below to invite the participant into fundraiser
        </div>
        <div className="flex">
          <div className="invitation-link">{invitationLink}</div>
          <CustomButton
            className={"custom-button"}
            title={"Copy"}
            onClick={() => {
              navigator.clipboard.writeText(invitationLink);
              Toaster("success", "Invitation link copied to clipboard!");
            }}
          />
        </div>
      </div>
    </CustomDialog>
  );
};

export default TeammemberLinkModal;
