import CustomLayout from "./components/common/CustomLayout";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import AdminSignUp from "./components/pages/Auth/AdminSignUp";
import { useEffect, useLayoutEffect } from "react";
import EmailVerify from "./components/pages/Auth/EmailVerify";
import AuthVerify from "./components/pages/Auth/AuthVerify";
import { ToastContainer } from "react-toastify";
import ResendEmail from "./components/pages/Auth/ResendEmail";
import ForgotPassword from "./components/pages/Auth/ForgotPassword";
import ChangePassword from "./components/pages/Auth/ChangePassword";
import Dashboard from "./components/pages/Admin/Dashboard";
import ParticipantsSignUp from "./components/pages/Auth/ParticipantsSignUp";
import ParticipantDashboardV2 from "./components/pages/Participants/DashboardV2";
import ParticipantRoute from "./components/common/ParticipantRoute";
import Home from "./components/pages/Home/Home";
import About from "./components/pages/Home/About";
import Game from "./components/pages/Home/Game";
import Supports from "./components/pages/Home/Supports";
import { getLoggedInUser } from "./apis/Auth";
import PrimaryAdminLogin from "./components/pages/Auth/PrimaryAdminLogin";
import ParticipantLogin from "./components/pages/Auth/ParticipantLogin";
import { useDispatch } from "react-redux";
import { getAuth } from "./slices/auth";
import TeamMemberDashboard from "./components/pages/TeamMember/TeamMemberDashboard";
import TeamMemberSign from "./components/pages/Auth/TeamMembertsSign";
import CustomTextArea from "./components/common/CustomTextArea";
import AccountTypeSelection from "./components/pages/Auth/AccountTypeSelection";
import FundraiserAdminDashboard from "./components/pages/Admin/Dashboard/FundraiserAdminDashboard";
import ParticipantsSignUpByLink from "./components/pages/Auth/ParticipantsSignUpByLink";
import FundraiserParticipantDashboard from "./components/pages/Participants/FundraiserParticipantDashboard";
import TeammemberLogin from "./components/pages/Auth/TeammemberLogin";
import StripeAccounts from "./components/pages/Stripe/StripeAccounts";
import Donate from "./components/pages/Donate";
import FundraiserTeammemberDashboard from "./components/pages/TeamMember/FundraiserTeammemberDashboard";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import DashboardRedirect from "./components/pages/DashboardRedirect";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("fundToken")) {
      const loggedInUser = getLoggedInUser();
      dispatch(getAuth(loggedInUser));
    }
  }, [localStorage]);

  return (
    <div>
      <Router>
        <Wrapper>
          <Routes>
            <Route path="/" element={<CustomLayout />}>
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/textarea" element={<CustomTextArea />} />
              <Route path="/auth/verify" element={<AuthVerify />} />
              <Route path="/admin/login" element={<PrimaryAdminLogin />} />
              <Route path="/dashboard" element={<DashboardRedirect />} />
              <Route
                path="/admin/stripeAccounts"
                element={<StripeAccounts />}
              />
              <Route
                path="/primaryadmin/login"
                element={<PrimaryAdminLogin isPrimaryAdminLogin />}
              />

              <Route index element={<Home />} />

              <Route path="/admin/signup" element={<AdminSignUp />} />
              <Route
                path="/admin/signup/:invitationCode"
                element={<AdminSignUp />}
              />
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route
                path="/admin/dashboard/:fundraiserId"
                element={<FundraiserAdminDashboard />}
              />

              <Route path="/home" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/game" element={<Game />} />
              <Route path="/support" element={<Supports />} />
              <Route path="/donate/:referalCode" element={<Donate />} />
              <Route
                path="/login"
                element={<AccountTypeSelection pageType={"login"} />}
              />
              <Route
                path="/signup"
                element={<AccountTypeSelection pageType={"signup"} />}
              />
              <Route path="/teammember/login" element={<TeammemberLogin />} />
              <Route
                path="/teammember/login/:invitationCode"
                element={<TeammemberLogin />}
              />
              <Route path="/teammember/signup" element={<TeamMemberSign />} />
              <Route
                path="/teammember/signup/:invitationCode"
                element={<TeamMemberSign />}
              />
              <Route
                path="/teammember/dashboard/:fundraiserId"
                element={<FundraiserTeammemberDashboard />}
              />
              <Route
                path="/teammember/dashboard"
                element={<TeamMemberDashboard />}
              />

              {/* <Route path="/school/signup" element={<SchoolSignUp />} /> */}

              <Route
                path="/participant/signup"
                element={<ParticipantsSignUp />}
              />
              <Route
                path="/participant/signup/:invitationcode"
                element={<ParticipantsSignUpByLink />}
              />
              <Route path="/participant/login" element={<ParticipantLogin />} />

              <Route path="/participant" element={<ParticipantRoute />}>
                <Route
                  path="/participant/dashboard"
                  element={<ParticipantDashboardV2 />}
                />
                <Route
                  path="/participant/dashboard/:fundraiserId"
                  element={<FundraiserParticipantDashboard />}
                />
              </Route>

              <Route path="/email-verification" element={<EmailVerify />} />
              <Route
                path="/email-verification/:token"
                element={<EmailVerify />}
              />
              <Route path="/resend-email" element={<ResendEmail />} />
              <Route
                path="/participant/forgot-password"
                element={<ForgotPassword role={"participant"} />}
              />
              <Route
                path="/admin/forgot-password"
                element={<ForgotPassword role={"admin"} />}
              />
              <Route
                path="/teammember/forgot-password"
                element={<ForgotPassword role={"teammember"} />}
              />
              <Route
                path="student/reset-password/:token"
                element={<ChangePassword />}
              />
              {/* <Route
                path=""
                element={<ChangePassword />}
              /> */}
            </Route>
          </Routes>
        </Wrapper>
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default App;
