import React, { useState } from "react";
import "./styles.scss";
import { Formik, Form } from "formik";
import CustomInput from "../../common/CustomInput";
import Spinner from "../../common/Spinner";
import CustomButton from "../../common/CustomButton";
import { adminLoginValidationSchema } from "../../../constants/validate";
import { logIn } from "../../../apis/Auth";
import { Toaster } from "../../../constants";
import { useNavigate } from "react-router-dom";

const PrimaryAdminLogin = () => {
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (values) => {
    try {
      setLoader(true);
      let response = await logIn(
        values.email,
        values.password,
        "participant",
        "phone"
      );
      navigate("/auth/verify", {
        state: {
          verificationSelection: "phone",
          email: values?.email,
          role: "participant",
          mode: "logIn",
          phone: response?.data?.phoneNo,
          redirect: "/participant/dashboard",
        },
      });
      setLoader(false);
      Toaster("success", response.message);
    } catch (error) {
      setLoader(false);
      let errorMessage =
        error.response?.data?.message || "Participant could not log in";
      Toaster("error", errorMessage);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={adminLoginValidationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        onSubmit={handleLogin}
      >
        {({ errors, handleSubmit }) => (
          <Form>
            <div className="participant-login-wrapper">
              <div className="title">Participant Login</div>
              <CustomInput label={"Email"} name="email" className={"input"} />
              <CustomInput
                label={"Password"}
                name="password"
                className={"input"}
                type="password"
              />
              <a href="/participant/forgot-password">Forgot Password</a>
              {loader ? (
                <Spinner />
              ) : (
                <div className="participant-button-wrapper">
                  <CustomButton
                    className={"login-button"}
                    title={"Login"}
                    type="submit"
                    onClick={() =>
                      !Object.keys(errors).length > 0 && handleSubmit
                    }
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PrimaryAdminLogin;
