import React from "react";
import ".././styles.scss";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "../../../../common/CustomInput";
import { Form, Formik } from "formik";
import CustomButton from "../../../../common/CustomButton";
import {
  SingleGoalValidationSchema,
  GroupGoalValidationSchema,
} from "../../../../../constants/validate";
import CustomDialog from "../../../../common/CustomDialog";
import Spinner from "../../../../common/Spinner";

const GoalModal = ({
  fundraiserData = null,
  onSubmit,
  submitText = "Submit",
  onSkip,
  skipText = "Skip",
  loader,
  updateFundraiserData = (data) => {},
  open,
  onClose,
  title,
  onBack,
  groupAccessIndex = null,
}) => {
  const getGoals = () => {
    const groupGoals = [];
    for (let i = 0; i < fundraiserData.groups.length; i += 1) {
      groupGoals.push(fundraiserData.groups[i].groupTarget);
    }
    const fundraiserGoal = fundraiserData?.fundraiserGoal;
    return { fundraiserGoal, groupGoals };
  };

  const noGroups =
    !fundraiserData?.groups || fundraiserData.groups.length === 0;

  const initialValue = noGroups
    ? { fundraiserGoal: fundraiserData?.fundraiserGoal || 0, groupGoals: null }
    : getGoals();

  const handleSubmit = (values) => {
    if (values.groupGoals)
      for (let i = 0; i < fundraiserData.groups.length; i += 1) {
        fundraiserData.groups[i].groupTarget = values.groupGoals[i];
      }
    fundraiserData.fundraiserGoal = values.fundraiserGoal;
    updateFundraiserData(fundraiserData);
    onSubmit();
  };

  const handleSkip = (e) => {
    e.preventDefault();
    onSkip();
  };

  const handleGroupGoalChange = (values, setFieldValue, e, index) => {
    if (groupAccessIndex !== null) {
      if (
        values.fundraiserGoal <
        Number(e.target.value || 0) +
          values.groupGoals.reduce((acc, obj) => acc + Number(obj || 0), 0) -
          values.groupGoals[index]
      ) {
        values.groupGoals[index] =
          values.fundraiserGoal -
          values.groupGoals.reduce((acc, obj) => acc + Number(obj || 0), 0) +
          Number(values.groupGoals[index]);

        setFieldValue(`groupGoals.${index}`, values.groupGoals[index]);
      } else values.groupGoals[index] = Number(e.target.value || 0);

      return;
    }
    values.groupGoals[index] = Number(e.target.value);
    setFieldValue(
      "fundraiserGoal",
      Math.max(
        values.fundraiserGoal || 0,
        values.groupGoals.reduce((acc, obj) => acc + Number(obj || 0), 0)
      )
    );
  };

  const handleCombineGoalChange = (values, setFieldValue, e) => {
    const groupGoal = Math.floor(e.target.value / values.groupGoals.length);

    if (
      e.target.value <
      values.groupGoals.reduce((acc, obj) => acc + Number(obj || 0), 0)
    )
      values.groupGoals.forEach((_, index) => {
        setFieldValue(`groupGoals.${index}`, groupGoal);
      });
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={title}
      onBackButtonClick={onBack}
    >
      <div className="goal-modal">
        {noGroups ? (
          <Formik
            initialValues={initialValue}
            onSubmit={handleSubmit}
            validationSchema={SingleGoalValidationSchema}
          >
            {({ handleSubmit, setFieldValue, errors, values, setValues }) => (
              <Form>
                <CustomInput
                  className={"custom-input"}
                  type={"number"}
                  step={"100"}
                  min={"0"}
                  label={"New Goal"}
                  name={"fundraiserGoal"}
                  placeholder="$100,000"
                ></CustomInput>
                <div className="button-group">
                  {loader ? (
                    <Spinner />
                  ) : (
                    <CustomButton
                      className={"custom-button"}
                      title={submitText}
                      onClick={handleSubmit}
                    ></CustomButton>
                  )}
                  <CustomButton
                    className={"custom-button"}
                    title={skipText}
                    onClick={handleSkip}
                    disabled={loader}
                  ></CustomButton>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            initialValues={initialValue}
            onSubmit={handleSubmit}
            validationSchema={GroupGoalValidationSchema}
          >
            {({ handleSubmit, setFieldValue, errors, values, setValues }) => (
              <Form>
                <CustomInput
                  className={"custom-input"}
                  type={"number"}
                  step={"100"}
                  min={"0"}
                  label={"Combine Goal"}
                  name={"fundraiserGoal"}
                  prefix={"$"}
                  placeholder="$100,000"
                  disabled={groupAccessIndex !== null}
                  onChange={(e) => {
                    handleCombineGoalChange(values, setFieldValue, e);
                  }}
                ></CustomInput>
                <div class="info">
                  Combined Goal should be at least sum of group goals
                </div>
                <div class="line-with-text">
                  <span>OR</span>
                </div>
                {values.groupGoals &&
                  values.groupGoals.map((groupGoal, index) => (
                    <div className="group-goal-wrapper">
                      <div className="heading">Group {index + 1}:</div>
                      <div className="group-name">
                        {fundraiserData.groups[index]?.name || ""}
                      </div>
                      <CustomInput
                        className={"custom-input"}
                        type={"number"}
                        step={"100"}
                        min={"0"}
                        name={`groupGoals.${index}`}
                        label={"Goal"}
                        placeholder="$50,000"
                        onChange={(e) =>
                          handleGroupGoalChange(values, setFieldValue, e, index)
                        }
                        disabled={
                          groupAccessIndex !== null &&
                          groupAccessIndex !== index
                        }
                      ></CustomInput>
                    </div>
                  ))}
                <div className="button-group">
                  {loader ? (
                    <Spinner />
                  ) : (
                    <CustomButton
                      className={"custom-button"}
                      title={submitText}
                      onClick={handleSubmit}
                    ></CustomButton>
                  )}
                  <CustomButton
                    className={"custom-button"}
                    title={skipText}
                    onClick={handleSkip}
                    disabled={loader}
                  ></CustomButton>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </CustomDialog>
  );
};

export default GoalModal;
