import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const CustomRadioForm = ({ radios, title, value, onChange , className}) => {
  return (
    <div className={`custom-radio-form ${className}`}>
      <FormControl>
        <div className="wrapper">
        <FormLabel className="title">{title}</FormLabel>
        <RadioGroup
          value={value}
          onChange={onChange}
         
        >
          <div  className="radio-group-wrapper">
          {radios.map((item , index) => {
            return (
              <FormControlLabel
                value={item.value}
                className="form-label"
                key={index}
                control={
                  <Radio
                    sx={{
                      color: "#E9C451",
                      "&.Mui-checked": {
                        color: "#E9C451",
                      },
                    }}
                  />
                }
                label={<p className="para">{item.name}</p>}
              />
            );
          })}
          </div>
        </RadioGroup>
        </div>
        
      </FormControl>
    </div>
  );
};

export default CustomRadioForm;
