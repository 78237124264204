import { CircularProgress } from "@mui/material";
import React from "react";

const Spinner = () => {
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        margin: "25px 0px 10px 0px",
      }}
    >
      <CircularProgress style={{ color: "#E9C451" }} />
    </div>
  );
};

export default Spinner;
