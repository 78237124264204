import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Formik } from "formik";
import CustomDialog from "../../../common/CustomDialog";
import Spinner from "../../../common/Spinner";
import CustomButton from "../../../common/CustomButton";
import CustomSelect from "../../../common/CustomSelect";
import { Toaster } from "../../../../constants";

const LinkStripeModal = ({
  stripeAccounts = [],
  selectedStripeAccount = null,
  onSubmit,
  submitText = "Link",
  loader,
  open,
  onClose,
  title,
}) => {
  const handleSubmit = (data) => {
    if (data.stripeAccountId === selectedStripeAccount)
      return Toaster("warning", "No change detected!");
    onSubmit(data);
  };
  // stripeAccounts = [
  //   { name: "A", accountId: 123 },
  //   { name: "A", accountId: 123 },
  //   { name: "A", accountId: 123 },
  //   { name: "A", accountId: 123 },
  // ];
  const initialValues = {
    stripeAccountId: selectedStripeAccount || stripeAccounts?.[0]?.accountId,
  };

  const stripeAccountsData = stripeAccounts?.map((account) => {
    return {
      label: account.name + " (" + account.accountId + ")",
      value: account.accountId,
    };
  });

  if (!stripeAccounts || stripeAccounts.length === 0)
    return (
      <CustomDialog open={open} onClose={onClose} title={title}>
        <div className="link-stripe-modal">
          <div className="info">
            You do not have any stripe account linked to the website.
          </div>
          <div className="info">
            Click{" "}
            <a href="/admin/stripeAccounts" target="_blank">
              HERE
            </a>{" "}
            to link a new stripe account.
          </div>
          <div className="info note">
            Note: You need to refresh the page after linking stripe account.
          </div>
        </div>
      </CustomDialog>
    );

  return (
    <CustomDialog open={open} onClose={onClose} title={title}>
      <div className="link-stripe-modal">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleSubmit, setFieldValue, errors, values, setValues }) => (
            <Form>
              <CustomSelect
                label={"Select Stripe Account"}
                name="stripeAccountId"
                placeholder="-----"
                options={stripeAccountsData}
                onChange={(event) =>
                  setValues({ stripeAccountId: event.target.value })
                }
              />
              <div>
                <a href="/admin/stripeAccounts" target="_blank">
                  Manage Accounts
                </a>
              </div>
              {loader ? (
                <Spinner />
              ) : (
                <CustomButton
                  className={"custom-button"}
                  title={submitText}
                  onClick={handleSubmit}
                ></CustomButton>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
};

export default LinkStripeModal;
