import axios from "../AxiosInstance";
import decode from "jwt-decode";
import { getAuth } from "../slices/auth";

export const verifyOtp =
  (email, role, mode, verificationSelection, otp) => (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .post(`/authRoutes/verifyOtp`, {
          email,
          role,
          verificationSelection,
          mode,
          otp,
        })
        .then((response) => {
          let token = response?.data?.data?.accessToken;
          if (token) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            const userDetails = {
              privileges: response?.data?.data?.privileges,
              lastName: response?.data?.data?.lastName,
            };
            localStorage.setItem("fundToken", token);
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            const decoded = {
              ...decode(token),
              ...userDetails,
            };
            dispatch(getAuth(decoded));
          }

          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

export const resendOtp = (email, role, mode, verificationSelection) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/authRoutes/resendOtp`, {
        email,
        role,
        verificationSelection,
        mode,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const logIn = (email, password, role, verificationSelection) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/authRoutes/login`, {
        email,
        password,
        role,
        verificationSelection,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });

export const getInvitationCodeData = (role, code) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/authRoutes/getInvitationData?role=${role}&invitationCode=${code}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const isLoggedIn = () => {
  let token = localStorage.getItem("fundToken");
  return token ? token : false;
};

export const getLoggedInUser = () => {
  if (isLoggedIn()) {
    const decoded = decode(isLoggedIn());
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    return { ...decoded, ...userDetails };
  } else return null;
};

export const verifyEmail = (token) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/adminRoutes/verifyEmail?token=${token}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const resendEmail = (firstName, email) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `/adminRoutes/reSendVerificationEmail?firstName=${firstName}&email=${email}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const sendForgetPasswordEmail = (email, role) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/studentRoutes/sendForgotPasswordEmail`, {
        email,
        role,
        verificationSelection: "phone",
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const sendForgetPasswordOtp = (email, role) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/authRoutes/sendForgotPasswordOtp`, {
        email,
        role,
        verificationSelection: "phone",
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const verifyResetPasswordToken = (token) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/studentRoutes/verifyResetPasswordToken`, { token })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const changeStudentPassword = (email, password, confirmPassword) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/studentRoutes/changeStudentPassword`, {
        email,
        password,
        confirmPassword,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const buyTokens = (amount, fundRaiserId, groupId) =>
  new Promise((resolve, reject) => {
    const body = fundRaiserId ? { amount, fundRaiserId } : { amount, groupId };
    axios
      .post("/authRoutes/buyTokens", body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getFundraiserDataByReferalCode = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/authRoutes/verifyReferalCode`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getDonationIntent = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/authRoutes/getDonations`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const changePassword = (email, password, role) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/authRoutes/changePassword`, {
        role,
        email,
        password,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
