import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const getDashboardLinkFromRole = (role) => {
  if (!role) return "/";
  if (role.toLowerCase().includes("admin")) return "/admin/dashboard";
  if (role === "participant") return "/participant/dashboard";
  if (role === "teammember") return "/teammember/dashboard";
  return "/";
};

const DashboardRedirect = () => {
  const navigate = useNavigate();

  const timeoutRef = useRef(null);

  const auth = useSelector((state) => state.auth.me);

  useEffect(() => {
    if (!auth) timeoutRef.current = setTimeout(() => navigate("/"), 500);
    else {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      navigate(getDashboardLinkFromRole(auth?.role));
    }
  }, [auth]);
};

export default DashboardRedirect;
