import React, { useState } from "react";
import ".././styles.scss";
import CustomButton from "../../../../common/CustomButton";
import "react-datepicker/dist/react-datepicker.css";
import CustomDialog from "../../../../common/CustomDialog";
import CustomTable from "../../../../common/CustomTable";
import Spinner from "../../../../common/Spinner";

const AssignParticipantModal = ({
  open,
  onClose,
  loader,
  participants,
  onAssign,
}) => {
  const participantsTableCols = [
    { name: "Name", dataIndex: "name" },
    { name: "Email", dataIndex: "email" },
  ];
  const [selectedParticipant, selectParticipant] = useState(null);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={"Assign participant to group"}
    >
      <div className="assign-participant-modal">
        <div className="table">
          <CustomTable
            rows={participants}
            columns={participantsTableCols}
            emptyDisplayText={"Fundraiser has no unassigned participants"}
            maxHeight={400}
            isSelection
            onSelect={selectParticipant}
          />
        </div>
        {loader ? (
          <Spinner />
        ) : (
          <div className="custom-btn-wrapper">
            <CustomButton
              title={"Assign"}
              className={"custom-btn"}
              disabled={selectedParticipant === null}
              onClick={() => onAssign(selectedParticipant)}
            />
          </div>
        )}{" "}
      </div>
    </CustomDialog>
  );
};

export default AssignParticipantModal;
