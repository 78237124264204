import axios from "../AxiosInstance";

export const teamMemberSignUp = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post("/teamMemberRoutes/teamMemberSignUp", body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const GetTeamMemberDashboard = (fundraiserId) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `/teamMemberRoutes/teamMemberDashboard?fundRaisingId=${fundraiserId}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getTeamMemberFundraisers = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`/teamMemberRoutes/getTeamMemberFundRaisers`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getTeamMemberInvitationData = (invitationCode) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `/teamMemberRoutes/getTeamMemberInvitationData?invitationCode=${invitationCode}`
      )
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const respondTeammemberInvitation = (invitationCode, response) =>
  new Promise((resolve, reject) => {
    axios
      .put("/teamMemberRoutes/invitationResponse", {
        invitationCode,
        response,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
