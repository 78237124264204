import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomButton from "../../common/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../common/CustomDialog";

const DeleteWarningModal = ({ open, onClose, data }) => {
  const navigate = useNavigate();

  if (!data) return null;

  const fundraisers = data.fundRaisers;
  const groups = data.groups;

  return (
    <CustomDialog open={open} onClose={onClose} title={"Resolve Dependencies"}>
      <div className="delete-warning-modal">
        <div>
          ⚠️Your stripe account is currently linked to some active fundraisers.
          Either unlink them for wait for the fundraisers to end.
        </div>
        {fundraisers.length > 0 &&
          fundraisers.map((fundraiser) => (
            <div style={{ display: "flex" }}>
              <div>{fundraiser.fundRaisingName}</div>
              <CustomButton
                title="Open"
                onClick={() => navigate(`/admin/dashboard/${fundraiser._id}`)}
              />
            </div>
          ))}
      </div>
    </CustomDialog>
  );
};

export default DeleteWarningModal;
