import React, { useEffect, useState } from "react";
import CustomButton from "../../common/CustomButton";
import { AiFillCheckCircle } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { resendEmail } from "../../../apis/Auth";
import Spinner from "../../common/Spinner";
import { Toaster } from "../../../constants";

const EmailVerify = () => {
  const [loader, setLoader] = useState(false);
  const [info, setInfo] = useState(null);

  const location = useLocation();

  const handleResendEmail = async () => {
    try {
      setLoader(true);
      await resendEmail(info.firstName, info.email);
      Toaster("success", "Email is sent successfully");
      setLoader(false);
    } catch (error) {
      setLoader(false);
      let errorMessage = error.response?.data?.message || "Email could not be sent"
      Toaster("error", errorMessage);
    }
  };

  useEffect(() => {
    if (location.state) {
      setInfo(location.state);
    }
  }, [location]);

  if (loader) {
    return <Spinner />;
  }
  return (
    <div className="resend-email-verification-wrapper">
      <div className="icon-wrapper">
        <AiFillCheckCircle className="icon" />
      </div>
      <div className="middle-section">
        <p className="paragraph">
          A link has been sent to your email for verification.
        </p>
        <p className="paragraph2">
          If you didn't receive it please click on resend button
        </p>
      </div>
      <CustomButton title={"Resend Link"} onClick={handleResendEmail} />
    </div>
  );
};

export default EmailVerify;
