import React, { useCallback, useEffect, useState } from "react";
import ".././styles.scss";
import "react-datepicker/dist/react-datepicker.css";
import StripeButton from "../../../../common/StripeButton";
import CustomButton from "../../../../common/CustomButton";
import CustomDialog from "../../../../common/CustomDialog";
import { getAllAccounts } from "../../../../../apis/Stripe";
import CustomSelect from "../../../../common/CustomSelect";
import Spinner from "../../../../common/Spinner";
import { Formik, Form } from "formik";

const StripeModal = ({
  fundraiserData = null,
  onSubmit,
  loader,
  updateFundraiserData,
  open,
  onClose,
  onBack,
}) => {
  const [stripeAccounts, setStripeAccounts] = useState(null);

  const stripeAccountsData = stripeAccounts
    ?.filter((account) => account.isVerified)
    .map((account) => {
      return {
        label: account.name + " (" + account.accountId + ")",
        value: account.accountId,
      };
    });

  const handleSubmit = (data) => {
    if (data)
      fundraiserData.fundRaisingStripe =
        data.stripeAccountId ||
        (stripeAccounts?.length ? stripeAccounts[0].accountId : undefined);
    updateFundraiserData(fundraiserData);
    onSubmit();
  };

  const handleSkip = (data) => {
    if (data) fundraiserData.fundRaisingStripe = data.stripeAccountId;
    updateFundraiserData(fundraiserData);
    onSubmit();
  };

  const initialValues = {
    stripeAccountId:
      fundraiserData.stripeAccountId || stripeAccounts?.[0]?.accountId,
  };

  const fetchStripeAccounts = useCallback(async () => {
    const accounts = await getAllAccounts();
    setStripeAccounts(accounts);
  }, []);

  useEffect(() => {
    fetchStripeAccounts();
  }, []);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={""}
      onBackButtonClick={onBack}
    >
      <div className="stripe-modal">
        <div className="title">Link Stripe Account</div>
        <div className="info">
          Top Score Fundraising does not manage donations. Setting up a Stripe
          account is a secure way to have funds transferred directly to you.
        </div>
        <div className="info">
          Competitive Groups may be set up after this with different Stripe
          accounts
        </div>
        <div className="more-info">
          Read <a href="#">Terms and conditions</a> for more information
        </div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleSubmit, setFieldValue, errors, values, setValues }) => (
            <Form className="centered">
              {stripeAccountsData && stripeAccountsData.length > 0 ? (
                <CustomSelect
                  label={"Select Stripe Account"}
                  name="stripeAccountId"
                  placeholder="-----"
                  options={stripeAccountsData}
                  onChange={(event) =>
                    setValues({ stripeAccountId: event.target.value })
                  }
                />
              ) : (
                <div className="no-stripe-text">
                  ⚠️You do not have any completely linked stripe accounts! Link
                  account and click refresh below or just skip for later
                </div>
              )}

              <div className="manage-accounts">
                <a href="/admin/stripeAccounts" target="_blank">
                  Manage Stripe Accounts
                </a>
                <p
                  onClick={() => {
                    setStripeAccounts(null);
                    fetchStripeAccounts();
                  }}
                >
                  {stripeAccounts ? "Refresh" : "Loading"}
                </p>
              </div>
              {loader ? (
                <Spinner />
              ) : (
                <CustomButton
                  title={"Next"}
                  className={"submit-button"}
                  onClick={handleSubmit}
                  disabled={!(stripeAccountsData && stripeAccountsData.length)}
                />
              )}
              <CustomButton
                title={"Skip"}
                className={"skip-button"}
                type="button"
                onClick={() => handleSkip()}
                disabled={!stripeAccounts}
              />
            </Form>
          )}
        </Formik>

        <div className="footer-info">
          A Strip account must be linked before the start of the fundraiser
        </div>
      </div>
    </CustomDialog>
  );
};

export default StripeModal;
