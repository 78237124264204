import React from "react";
import CustomButton from "./CustomButton";
import "./styles.scss";
import Spinner from "./Spinner";

const OtpVerification = ({
  otp,
  handleChange,
  header,
  resendCode,
  verify,
  communicationMedia,
  loader,
}) => {
  return (
    <div className="otp-verification-wrapper">
      <div className="header">{header}</div>
      <p className="para">
        An OTP code has been sent to{" "}
        <span className="email">{communicationMedia || "your phone number."}</span>
      </p>
      <div className="otp-wrapper">
        {otp.map((code, index) => (
          <input
            type="text"
            key={index}
            className="otp"
            value={code}
            onChange={(e) => handleChange(e, index)}
            maxLength={1}
            onFocus={(e) => e.target.select()}
            autoFocus={index === 0}
          />
        ))}
      </div>
      <div className="resend-wrapper">
        <span>Didn't recieve Code?</span>
        <span onClick={resendCode}>Resend Code</span>
      </div>
      {loader ? (
        <Spinner />
      ) : (
        <CustomButton
          className={"continue-button"}
          title={"Verify"}
          onClick={verify}
        />
      )}
    </div>
  );
};

export default OtpVerification;
