import React, { useState } from "react";
import CustomDialog from "../../common/CustomDialog";
import { Form, Formik } from "formik";
import { adminLoginValidationSchema } from "../../../constants/validate";
import CustomInput from "../../common/CustomInput";
import Spinner from "../../common/Spinner";
import CustomButton from "../../common/CustomButton";

const LoginModal = ({
  open = false,
  onClose,
  onLogin,
  role="admin",
  title = "Admin Login",
}) => {
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (values, actions) => {
    try {
      setLoader(true);
      await onLogin(values);
      actions.setValues({ email: "", password: "" });
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <CustomDialog title={title} open={open} onClose={onClose}>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={adminLoginValidationSchema}
        validateOnBlur={true}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ errors }) => (
          <Form>
            <div className="login-wrapper">
              <CustomInput label={"Email"} name="email" className={"input"} />
              <CustomInput
                label={"Password"}
                name="password"
                className={"input"}
                type="password"
              />
              <a href={`${role}/forgot-password`}>Forgot Password</a>
              {loader ? (
                <Spinner />
              ) : (
                <CustomButton
                  className={"login-button"}
                  title={"Login"}
                  type="submit"
                  disabled={Object.keys(errors).length > 0}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};

export default LoginModal;
