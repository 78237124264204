import React, { useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomDialog from "./CustomDialog";
import CustomButton from "./CustomButton";
import Spinner from "./Spinner";

const UploadFileModal = ({
  title,
  prompt,
  open,
  onClose,
  loader,
  onFileUpload,
}) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onFileUpload(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <CustomDialog open={open} onClose={onClose} title={title}>
      <div className="confirmation-modal">
        <div className="info">{prompt}</div>
        {loader ? (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        ) : (
          <CustomButton
            className={"custom-button"}
            title={"Cancel"}
            onClick={onClose}
          />
        )}
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <CustomButton
          className={"custom-button"}
          title={"Upload"}
          onClick={triggerFileInput}
        />
      </div>
    </CustomDialog>
  );
};

export default UploadFileModal;
