import React from "react";
import "./styles.scss";

const CustomButton = ({ title, className, disabled = false, ...rest }) => {
  return (
    <button
      disabled={disabled}
      className={` custom-button-wrapper ${className} ${
        disabled && "disable-button"
      }`}
      {...rest}
    >
      {title}
    </button>
  );
};

export default CustomButton;
