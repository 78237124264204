import React, { useCallback, useEffect, useState } from "react";
import "../Home/home.scss";
import CustomButton from "../../common/CustomButton";
import cardImg1 from "../../../assets/cardImg1.png";
import cardImg2 from "../../../assets/cardImg2.png";
import cardImg3 from "../../../assets/cardImg3.png";
import cardImg4 from "../../../assets/cardImg4.png";
import cardImg5 from "../../../assets/cardImg5.png";
import scaner from "../../../assets/scanerImg.png";
import CustomTable from "../../common/CustomTable";
import {
  CustomNextArrow,
  CustomPrevArrow,
} from "../../common/CustomSliderArrow";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation, useNavigate } from "react-router-dom";
import { isLoggedIn } from "../../../apis/Auth";
import { GetAdminFundRaisings } from "../../../apis/Admin";
import { useSelector } from "react-redux";

function Home() {
  const tableColumn = [
    { name: "Name", dataIndex: "fundRaisingName" },
    { name: "Donation", dataIndex: "totalFunding", prefix: "$" },
  ];
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };
  const cardImages = [cardImg1, cardImg2, cardImg3, cardImg4, cardImg5];
  const location = useLocation();

  const [fundraisers, setFundraisers] = useState(null);

  const [selectedFundraiser, selectFundraiser] = useState(null);

  const auth = useSelector((state) => state.auth.me);

  const navigate = useNavigate();

  const handleFetchFundraisers = useCallback(async () => {
    const fundraisersList = await GetAdminFundRaisings();
    setFundraisers(fundraisersList);
  }, []);

  useEffect(() => {
    if (!fundraisers && auth && auth.role === "secondaryAdmin") {
      handleFetchFundraisers();
    }
  }, [localStorage, location, auth]);

  return (
    <div className="home-main-wrapper">
      <Slider {...sliderSettings}>
        {cardImages.map((image, index) => (
          <div key={index} className="image-slide">
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{ width: "100%", maxHeight: "500px" }}
            />
          </div>
        ))}
      </Slider>
      <br />
      <p className="home_description">
        Top Score Fundraising is a fun, interactive fundraising platform
        designed to encourage student participation through games and big
        prizes. Don’t waste time and resources pushing kids to do something that
        takes extra motivation. Let them do what they are going to do anyway and
        raise money for your school. Our fundraiser is easy to set up and no
        upfront or hidden costs.
      </p>
      <br />
      <div className="home-btn-wrapper-top">
        <CustomButton
          title={"Create Account"}
          className="home_description-btn-top"
          onClick={() => navigate("/signup")}
        />
        <CustomButton
          title={"Request information"}
          className="home_description-btn-top"
          onClick={() => navigate("/support")}
        />
      </div>
      <br />
      {/* ========= cards ======== */}
      <div className="cards-wrapper">
        <div className="card-img-wrapper">
          <img src={cardImg1} className="card-img" />
        </div>
        <div className="card-para-wrapper">
          <p>
            At least 70% of all money raised goes to the student fundraiser !
          </p>
        </div>
      </div>

      <div className="cards-wrapper2">
        <div className="card-para-wrapper2">
          <p>
            Set to work over the web to make it both computer and mobile
            friendly
          </p>
        </div>
        <div className="card-img-wrapper2">
          <img src={cardImg2} className="card-img" />
        </div>
      </div>

      <div className="cards-wrapper">
        <div className="card-img-wrapper">
          <img src={cardImg3} className="card-img" />
        </div>
        <div className="card-para-wrapper">
          <p>
            Motivate participants through games and challenges to get big
            rewards
          </p>
        </div>
      </div>

      <div className="cards-wrapper2">
        <div className="card-para-wrapper2">
          <p>
            Earn achievement to get extra tokens and gift cards et to work over
            the web to make it both computer and mobile friendly
          </p>
        </div>
        <div className="card-img-wrapper2">
          <img src={cardImg4} className="card-img" />
        </div>
      </div>

      <div className="cards-wrapper">
        <div className="card-img-wrapper">
          <img src={cardImg5} className="card-img" />
        </div>
        <div className="card-para-wrapper">
          <p>
            E-mail and text messages makes spread awareness easy and seamless
          </p>
        </div>
      </div>
      <br></br>
      <br></br>
      {auth && auth.role === "secondaryAdmin" && (
        <React.Fragment>
          <p className="home_description">Track your fundraiser in real time</p>
          <div className="home-table-wrapper">
            <CustomTable
              columns={tableColumn}
              rows={fundraisers || []}
              loader={!fundraisers}
              emptyDisplayText={"You do not currently have any fundraisers!"}
              isSelection
              onSelect={selectFundraiser}
            />
            <CustomButton
              className="custom-btn"
              title="Open Fundraiser"
              disabled={selectedFundraiser === null}
              onClick={() => {
                navigate(
                  "/admin/dashboard/" + fundraisers[selectedFundraiser]._id
                );
              }}
            />
          </div>
        </React.Fragment>
      )}
      <div className="cards-wrapper">
        <div className="card-img-wrapper">
          <img src={scaner} className="scaner-img" />
        </div>
        <div className="card-para-wrapper">
          <p>Use QR codes to easily get donations via mobile devices</p>
        </div>
      </div>
      {/* ========= Buttons ======== */}
      <div className="home-btn-wrapper">
        <CustomButton
          title={"Signup"}
          className="home_description-btn2"
          onClick={() => navigate("/signup")}
        />
        <CustomButton
          title={"Request information"}
          className="home_description-btn2"
          onClick={() => navigate("/support")}
        />
      </div>
    </div>
  );
}

export default Home;
