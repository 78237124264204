import React from "react";
import ".././styles.scss";
import CustomButton from "../../../../common/CustomButton";
import "react-datepicker/dist/react-datepicker.css";
import CustomDialog from "../../../../common/CustomDialog";
import { Toaster } from "../../../../../constants";

const LinkModal = ({ open, onClose, invitationLink, title,info }) => {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={"Participant Invitation Link"}
    >
      <div className="link-modal">
        <div className="info">
          Use this link below to invite the participant into fundraiser
        </div>
        <div className="flex">
          <div className="invitation-link">{invitationLink}</div>
          <CustomButton
            className={"custom-button"}
            title={"Copy"}
            onClick={() => {
              navigator.clipboard.writeText(invitationLink);
              Toaster("success", "Invitation link copied to clipboard!");
            }}
          />
        </div>
      </div>
    </CustomDialog>
  );
};

export default LinkModal;
