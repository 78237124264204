import { BsCoin } from "react-icons/bs";
import { RiOrganizationChart } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { toast } from "react-toastify";

export const leftSideBarItems = [
  {
    title: "Dashboard",
    Icon: RiOrganizationChart,
    path: "/dashboard",
  },
  {
    title: "Login",
    Icon: FaUserAlt,
    path: "/login",
  },
];

export const menuItems = [
  { title: "Home", path: "/" },
  { title: "About", path: "/about" },
  { title: "Games", path: "/game" },
  { title: "Support", path: "/support" },
];

export const tempSchools = [
  { value: "APS", label: "APS" },
  { value: "Bob’s High School", label: "Bob’s High School" },
  { value: "Top Class school", label: "Top Class school" },
  { value: "APSACS", label: "APSACS" },
  { value: "Carson Spencer", label: "Carson Spencer" },
  { value: "LAMN", label: "LAMN" },
];

export const tempOrgs = [
  { value: "Cricket MC", label: "Cricket MC" },
  { value: "Football MC", label: "Football MC" },
];

export const Toaster = (type, message) => {
  switch (type) {
    case "success":
      return toast.success(message);
    case "error":
      return toast.error(message);
    case "warning":
      return toast.warning(message);

    default:
      return toast.success("This is a default toaster");
  }
};

export const findMessage = (obj, defaultMessage) => {
  let deepestMessage = defaultMessage;

  const searchDeep = (currentObj) => {
    if (currentObj !== null && typeof currentObj === "object") {
      if ("message" in currentObj) {
        deepestMessage = currentObj.message;
      }

      for (const key in currentObj) {
        if (
          currentObj.hasOwnProperty(key) &&
          typeof currentObj[key] === "object"
        ) {
          searchDeep(currentObj[key]);
        }
      }
    }
  };

  searchDeep(obj);

  return deepestMessage;
};

export const NEW_FUNDRAISER_STAGES = {
  FUNDRAISER_INFO: 0,
  SIMILAR_FUNDRAISERS: 1,
  GROUPS: 2,
  GOAL: 3,
  CAUSE: 4,
  LINK_STRIPE: 5,
};

export const PREVIOUS_FUNDRAISER_STAGES = {
  SELECTOR: -1,
};

export const DASHBOARD_MODALS = {
  FUNDRAISER_GOAL: 0,
  GROUP_GOAL: 1,
  FUNDRAISER_CAUSE: 2,
  GROUP_CAUSE: 3,
  INVITE_PARTICIPANT: 4,
  ASSIGN_PARTICIPANT: 5,
  LINK: 6,
  EDIT_FUNDRAISER: 7,
  DELETE_FUNDRAISER: 8,
  CREATE_GROUPS: 9,
  ADD_CONTACT: 10,
  UPDATE_CONTACT: 11,
  DELETE_CONTACT: 12,
  INVITE_TEAMMEMBER: 13,
  TEAMMEMBER_LINK: 14,
  PERMISSION_MODAL: 15,
  GROUP_NAME: 16,
  DELETE_GROUP: 17,
  NEW_GROUP: 18,
  NEW_GROUPS: 19,
  GROUP_STRIPE_MODAL: 20,
  FUNDRAISER_STRIPE_MODAL: 21,
  PURCHASE_TOKENS: 22,
  TOKEN_AMOUNT_SELECTOR: 23,
  UPLOAD_FILE: 24,
  INVITE_ADMIN: 25,
  TOKEN_SHARE: 26,
  NEW_GAME: 27,
  UPDATE_GAME: 28,
  DELETE_GAME: 29,
  MANUAL_DONATION: 30,
};

export const FUNDRAISER_MODALS = {
  NEW_FUNDRAISER: 0,
  UPDATE_FUNDRAISER: 1,
  DELETE_FUNDRAISER: 2,
};

export const DONATION_REFERAL_TYPES = {
  PARTICIPANT: "participant",
  GROUP: "group",
  FUNDRAISER: "fundraiser",
  INVALID: "invalid",
};

export const PAYMENT_REASONS = {
  TOKENS: "tokens",
  DONATION: "donation",
};

export const FUNDRAISER_STATUS = {
  NOT_STARTED: "Not Started",
  STARTED: "Started",
  STARTED_BUT_INACTIVE: "Started But Inactive",
  ENDED: "Ended",
  ERROR: "Error",
};
