import React from "react";
import CustomDialog from "../../../common/CustomDialog";
import { Form, Formik } from "formik";
import CustomInput from "../../../common/CustomInput";
import CustomButton from "../../../common/CustomButton";
import Spinner from "../../../common/Spinner";
import { shareTokensValidationSchema } from "../../../../constants/validate";
import { Toaster } from "../../../../constants";

const ShareTokenModal = ({
  open,
  onClose,
  onSubmit,
  loader,
  maxTokens,
  teammemberInfo,
}) => {
  const handleSubmit = (values) => {
    if (values.tokens > maxTokens)
      return Toaster("warning", "Not enough tokens to share!");
    onSubmit(values.tokens);
  };

  return (
    <CustomDialog open={open} onClose={onClose} title={"Share Tokens"}>
      <Formik
        initialValues={{
          tokens: 0,
        }}
        onSubmit={handleSubmit}
        validationSchema={shareTokensValidationSchema}
        validateOnChange={true}
      >
        {({ handleSubmit, errors, values, setValues }) => (
          <Form>
            <div className="share-tokens-modal">
              {teammemberInfo && (
                <div className="info">
                  <span className="highlight">Sending tokens to: </span>
                  <span>
                    {`${teammemberInfo.firstName} ${teammemberInfo.lastName} (${teammemberInfo.email})`}
                  </span>
                </div>
              )}
              <CustomInput
                name="tokens"
                label={"Tokens to share"}
                placeholder="20"
                type={"number"}
                step={"2"}
                min={"1"}
                max={maxTokens}
              />
              <div className="warning">
                You can share at max
                <strong> {maxTokens} </strong>
                tokens
              </div>
              <div className="submit-button">
                {loader ? (
                  <Spinner />
                ) : (
                  <CustomButton
                    title={"Confirm"}
                    className={"submit"}
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};

export default ShareTokenModal;
