import { useCallback, useEffect, useState } from "react";
import Spinner from "../../common/Spinner";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import { buyTokens, getDonationIntent } from "../../../apis/Auth";
import { Elements } from "@stripe/react-stripe-js";
import { PAYMENT_REASONS, Toaster } from "../../../constants";

const calculateAmount = (tokens) => {
  return tokens * 2;
};

const PaymentFormWrapper = ({
  amount,
  tokens,
  reason,
  referalCode,
  fundRaiserId = null,
  groupId = null,
  onClose,
  sponsorData,
}) => {
  const stripePromise = loadStripe(
    "pk_test_51Ne2cfAeKQJj1FWy4O57VBbCBwl7p9p2BSt9BcZGz9wk8qliUeLkxB3UmAOxqc9QhVmlKTmQKtoIkDUvOXEuo33D001fjHh42H"
  );
  const [clientSecret, setClientSecret] = useState(null);

  const appearance = {
    theme: "night",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const initializePurchase = useCallback(async () => {
    if (!amount && !tokens) return;
    try {
      if (reason === PAYMENT_REASONS.DONATION) {
        const data = await getDonationIntent({
          amount,
          referalCode,
          ...sponsorData,
        });
        setClientSecret(data.data.clientSecret);
      } else {
        const data = await buyTokens(
          calculateAmount(tokens),
          fundRaiserId,
          groupId
        );
        setClientSecret(data.clientSecret);
      }
    } catch (e) {
      Toaster(
        "error",
        e?.response?.data?.message ||
          e?.message ||
          "Error while initializing tokens purchase!"
      );
      onClose();
    }
  }, [amount, tokens]);

  useEffect(() => {
    initializePurchase();
  }, [amount, tokens]);

  if (!clientSecret || (!amount && !tokens)) return <Spinner />;

  return (
    <div className="payment-form-wrapper">
      <div className="amount-visual">
        {reason === PAYMENT_REASONS.DONATION
          ? `Performing donation of $${amount}`
          : `Purchasing ${tokens} tokens for $${calculateAmount(tokens)}`}
      </div>
      <Elements options={options} stripe={stripePromise}>
        <PaymentForm clientSecret={clientSecret} />
      </Elements>
    </div>
  );
};

export default PaymentFormWrapper;
