import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Formik } from "formik";
import CustomDialog from "../../common/CustomDialog";
import { newStripeAccountValidationSchema } from "../../../constants/validate";
import CustomInput from "../../common/CustomInput";
import Spinner from "../../common/Spinner";
import CustomButton from "../../common/CustomButton";
import CustomSelect from "../../common/CustomSelect";

const NewAccountModal = ({ onSubmit, loader, open, onClose }) => {
  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const initialValue = {
    name: "",
    // buisnessType: "indiviual",
    // firstName: "",
    // lastName: "",
    // buisnessName: ".",
  };

  // const buisnessTypeOptions = [
  //   { label: "Indiviual", value: "indiviual" },
  //   { label: "Company", value: "company" },
  // ];

  return (
    <CustomDialog open={open} onClose={onClose} title={"New Account"}>
      <div className="cause-modal">
        <Formik
          initialValues={initialValue}
          onSubmit={handleSubmit}
          validationSchema={newStripeAccountValidationSchema}
        >
          {({ handleSubmit, setFieldValue, errors, values, setValues }) => (
            <Form>
              <div className="combined-cause">
                <CustomInput
                  className={"custom-input"}
                  label={"Display Name"}
                  name={"name"}
                  placeholder="My stripe account"
                ></CustomInput>
                {/* <div>
                  <CustomSelect
                    type="text"
                    label={"Account Type"}
                    name="fundraiser"
                    options={buisnessTypeOptions}
                    onChange={(e) => {
                      const buisnessType = e.target.value;
                      if (buisnessType === "indiviual")
                        setValues({
                          ...values,
                          buisnessName: ".",
                          buisnessType,
                          firstName: "",
                          lastName: "",
                        });
                      else
                        setValues({
                          ...values,
                          firstName: ".",
                          lastName: ".",
                          buisnessType,
                          buisnessName: "",
                        });
                    }}
                  />
                </div>
                {values.buisnessType === "indiviual" && (
                  <div>
                    <CustomInput label={"First Name"} name={"firstName"} />
                    <CustomInput label={"Last Name"} name={"lastName"} />
                  </div>
                )}
                {values.buisnessType === "company" && (
                  <div>
                    <CustomInput
                      label={"Buisness Name"}
                      name={"buisnessName"}
                    />
                  </div>
                )} */}
              </div>
              {loader ? (
                <Spinner />
              ) : (
                <CustomButton
                  className={"custom-button"}
                  title={"Submit"}
                  onClick={handleSubmit}
                ></CustomButton>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
};

export default NewAccountModal;
