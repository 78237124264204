import PaymentFormWrapper from "./PaymentFormWrapper";
import CustomDialog from "../../common/CustomDialog";

const PaymentModal = ({
  open,
  onClose,
  title,
  onBack = () => {},
  amount,
  tokens,
  reason,
  referalCode,
  fundRaiserId,
  groupId,
  sponsorData,
}) => {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={title}
      onBackButtonClick={onBack}
    >
      <PaymentFormWrapper
        amount={amount}
        tokens={tokens}
        reason={reason}
        sponsorData={sponsorData}
        referalCode={referalCode}
        fundRaiserId={fundRaiserId}
        groupId={groupId}
        onClose={onClose}
      />
    </CustomDialog>
  );
};

export default PaymentModal;
