import CustomButton from "./CustomButton";
import CustomDialog from "./CustomDialog";
import { Form, Formik } from "formik";
import { tokenPurchaseAmountValidationSchema } from "../../constants/validate";
import CustomInput from "./CustomInput";

const TokenSelectorModal = ({ open, onClose, onSubmit }) => {
  const handleSubmit = (values) => {
    onSubmit(values.amount);
  };

  const handlePriceSelect = (amount) => {
    onSubmit(amount);
  };

  return (
    <CustomDialog open={open} onClose={onClose} title={"Token Purchase"}>
      <Formik
        initialValues={{ amount: 20 }}
        onSubmit={handleSubmit}
        validationSchema={tokenPurchaseAmountValidationSchema}
      >
        {({ handleSubmit, setValues }) => (
          <Form>
            <div className="token-selector-modal">
              <div className="token-buttons-wrapper">
                <div className="token-heading-wrapper">
                  <h2 className="token-heading">1 Token = $2.0</h2>
                </div>
                <div className="btn-group">
                  <div className="btn">
                    <CustomButton
                      title={"10"}
                      className="token-btn"
                      onClick={() => setValues({ amount: 10 })}
                    />
                    <p></p>
                  </div>
                  <div className="btn">
                    <CustomButton
                      title={"15"}
                      className="token-btn"
                      onClick={() => setValues({ amount: 15 })}
                    />
                    <p></p>
                  </div>
                  <div className="btn">
                    <CustomButton
                      title={"20"}
                      className="token-btn"
                      onClick={() => setValues({ amount: 20 })}
                    />
                  </div>
                </div>
                <div className="btn-group">
                  <div className="btn">
                    <CustomButton
                      title={"50"}
                      className="token-btn"
                      onClick={() => setValues({ amount: 50 })}
                    />
                  </div>
                  <div className="btn">
                    <CustomButton
                      title={"75"}
                      className="token-btn"
                      onClick={() => setValues({ amount: 75 })}
                    />
                  </div>
                  <div className="btn">
                    <CustomButton
                      title={"100"}
                      className="token-btn"
                      onClick={() => setValues({ amount: 100 })}
                    />
                  </div>
                </div>
                <CustomInput
                  className={"custom-input"}
                  title={"Custom Amount"}
                  name={"amount"}
                  type={"number"}
                  min={1}
                />
                <CustomButton
                  title={"Custom"}
                  className="custom-btn"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};

export default TokenSelectorModal;
