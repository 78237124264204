import React, { useMemo, useState } from "react";
import { Form, Formik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import CustomDialog from "../../../common/CustomDialog";
import { contactValidationSchema } from "../../../../constants/validate";
import CustomInput from "../../../common/CustomInput";
import CustomPhone from "../../../common/CustomPhone";
import Spinner from "../../../common/Spinner";
import CustomButton from "../../../common/CustomButton";
import CustomSelect from "../../../common/CustomSelect";

const UpdateContactModal = ({
  onSubmit,
  loader,
  open,
  onClose,
  contacts,
  selectedContact,
}) => {
  const isEqual = (contact1, contact2) => {
    return (
      contact1?.fullName == contact2?.fullName &&
      contact1?.email == contact2?.email &&
      contact1?.phoneNumber == contact2?.phoneNumber
    );
  };

  const handleFormSubmit = (values, actions) => {
    const updatedContacts = contacts;
    updatedContacts[selectedContact] = {
      ...updatedContacts[selectedContact],
      ...values,
    };
    onSubmit({ updatedContacts, index: selectedContact });
  };

  const getContactIndex = (phoneNumber) =>
    contacts.findIndex((contact) => contact.phoneNumber === phoneNumber);

  const getContactData = (index) => {
    return {
      fullName: contacts[index]?.fullName,
      email: contacts[index]?.email,
      phoneNumber: contacts[index]?.phoneNumber,
    };
  };

  const initialValues = getContactData(selectedContact);

  const contactList = contacts?.map((contact) => ({
    label: contact.fullName,
    value: contact.phoneNumber,
  }));

  return (
    <CustomDialog open={open} onClose={onClose} title={"Update Contact"}>
      <div className="contact-modal">
        <div className="info-modal-wrapper">
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
            validationSchema={contactValidationSchema}
          >
            {({ handleSubmit, setFieldValue, errors, values, setValues }) => (
              <Form>
                <div className="form-section">
                  <CustomInput
                    label={"Name"}
                    name="fullName"
                    className={"input"}
                    placeholder={"Bob"}
                  />
                  <CustomInput
                    label={"Email Address"}
                    name="email"
                    className={"input"}
                    placeholder={"BobSmith123@gmail.com"}
                  />
                  <CustomPhone
                    label={"Phone"}
                    name="phoneNumber"
                    className={"input"}
                    placeholder={"XXXX-XXXX-X"}
                  />

                  <div className="button-group">
                    {loader ? (
                      <Spinner />
                    ) : (
                      <CustomButton
                        title={"Update"}
                        onClick={handleSubmit}
                        className={"submit-button"}
                        disabled={
                          Object.keys(errors).length > 0 ||
                          isEqual(contacts[selectedContact], values)
                        }
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </CustomDialog>
  );
};

export default UpdateContactModal;
