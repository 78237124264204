import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomDialog from "./CustomDialog";
import CustomButton from "./CustomButton";
import Spinner from "./Spinner";

const ConfirmationModal = ({
  title,
  prompt,
  open,
  onClose,
  onCancel = null,
  onConfirmation,
  confirmationText = "Confirm",
  cancelText = "Cancel",
  warningOnConfirm = false,
  loader,
}) => {
  return (
    <CustomDialog open={open} onClose={onClose} title={title}>
      <div className="confirmation-modal">
        <div className="info">{prompt}</div>
        <div className="flex">
          {loader ? (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          ) : (
            <CustomButton
              className={warningOnConfirm ? "warning-button" : "custom-button"}
              title={confirmationText}
              onClick={onConfirmation}
            />
          )}
          <CustomButton
            className={"custom-button"}
            title={cancelText}
            onClick={onCancel || onClose}
          />
        </div>
      </div>
    </CustomDialog>
  );
};

export default ConfirmationModal;
