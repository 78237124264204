import { Form, Formik } from "formik";
import React, { useState } from "react";
import CustomInput from "../../common/CustomInput";
import CustomButton from "../../common/CustomButton";
import * as Yup from "yup";
import Spinner from "../../common/Spinner";
import {
  resendOtp,
  sendForgetPasswordEmail,
  sendForgetPasswordOtp,
} from "../../../apis/Auth";
import { Toaster } from "../../../constants";
import SuccessMessage from "../../common/SuccessMessage";
import { useNavigate } from "react-router-dom";

const ForgotPassword = ({ role }) => {
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("It must be a valid email")
      .required("Email is required*")
      .trim(),
  });

  const handleFormSubmit = async (values, actions) => {
    try {
      setLoader(true);
      const mode = "logIn";
      const verificationSelection = "phone";
      let response = await resendOtp(
        values.email,
        role,
        mode,
        verificationSelection
      );
      setSuccess(true);
      actions.setValues({ email: "" });
      setLoader(false);
      navigate("/auth/verify", {
        state: {
          verificationSelection,
          email: values?.email,
          role,
          mode,
          redirect: `/${role}/dashboard`,
        },
      });
    } catch (error) {
      actions.setValues({ email: "" });
      let errorMessage =
        error.response?.data?.message || "Otp could not be sent";
      Toaster("error", errorMessage);
      setLoader(false);
    }
  };

  return (
    <div className="forgot-password-wrapper">
      {success ? (
        <SuccessMessage
          message={"Kindly check your phone. OTP has been send. Redirecting..."}
        />
      ) : (
        <div className="container">
          <div className="title">Forgot Password</div>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={handleFormSubmit}
          >
            {({ handleSubmit, values }) => (
              <Form>
                <CustomInput
                  label={"Email"}
                  type={"text"}
                  name="email"
                  placeholder="xyz@gmail.com"
                />
                <div className="send-email-button-wrapper">
                  {loader ? (
                    <Spinner />
                  ) : (
                    <CustomButton
                      disabled={values.email === ""}
                      title={"Send Email"}
                      className={"send-email-button"}
                      onClick={handleSubmit}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
