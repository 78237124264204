import { ReactComponent as ArrowRight } from "../../assets/Arrow - Right.svg";
import { ReactComponent as ArrowLeft } from "../../assets/Arrow Left.svg";
import { ReactComponent as Ellipse } from "../../assets/Ellipse.svg";

export function CustomNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="custom-arrow-right" onClick={onClick}>
      <Ellipse style={{ position: "absolute" }}></Ellipse>
      <ArrowRight
        style={{
          position: "absolute",
          zIndex: 2,
          width: "30px",
          height: "30px",
          margin: "29px",
        }}
      />
    </div>
  );
}
export function CustomPrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="custom-arrow-left" onClick={onClick}>
      <Ellipse style={{ position: "absolute" }}></Ellipse>
      <ArrowLeft
        style={{
          position: "absolute",
          zIndex: 2,
          width: "30px",
          height: "30px",
          margin: "26px",
        }}
      />
    </div>
  );
}
