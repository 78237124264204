import React from "react";
import { Form, Formik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import CustomDialog from "../../../common/CustomDialog";
import { contactValidationSchema } from "../../../../constants/validate";
import CustomInput from "../../../common/CustomInput";
import CustomPhone from "../../../common/CustomPhone";
import Spinner from "../../../common/Spinner";
import CustomButton from "../../../common/CustomButton";

const AddContactModal = ({ onSubmit, loader, open, onClose }) => {
  const handleFormSubmit = (values, actions) => {
    onSubmit(values);
  };

  const initialValues = {
    fullName: "",
    email: "",
    phoneNumber: "",
  };

  return (
    <CustomDialog open={open} onClose={onClose} title={"Add New Contact"}>
      <div className="contact-modal">
        <div className="info-modal-wrapper">
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
            validationSchema={contactValidationSchema}
          >
            {({ handleSubmit, setFieldValue, errors, values, setValues }) => (
              <Form>
                <div className="form-section">
                  <CustomInput
                    label={"Name"}
                    name="fullName"
                    className={"input"}
                    placeholder={"Bob"}
                  />
                  <CustomInput
                    label={"Email Address"}
                    name="email"
                    className={"input"}
                    placeholder={"BobSmith123@gmail.com"}
                  />
                  <CustomPhone
                    label={"Phone"}
                    name="phoneNumber"
                    className={"input"}
                    placeholder={"XXXX-XXXX-X"}
                  />

                  <div className="button-group">
                    {loader ? (
                      <Spinner />
                    ) : (
                      <CustomButton
                        title={"Confirm"}
                        onClick={handleSubmit}
                        className={"submit-button"}
                        disabled={Object.keys(errors).length > 0}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </CustomDialog>
  );
};

export default AddContactModal;
