import React, { useEffect, useState } from "react";
import "./styles.scss";
import CustomButton from "../../common/CustomButton";
import CustomInput from "../../common/CustomInput";
import { Grid } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomCheckBox from "../../common/CustomCheckBox";
import ReCaptchaV2 from "react-google-recaptcha";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import Spinner from "../../common/Spinner";
import { Toaster } from "../../../constants";
import { participantsSignUpByLinkValidation } from "../../../constants/validate";
import { participantSignUp } from "../../../apis/Participants";
import { getInvitationCodeData, logIn } from "../../../apis/Auth";
import LoginModal from "./LoginModal";

const ParticipantsSignUpByLink = () => {
  const [openLoginForm, setOpenLoginForm] = useState(false);
  const [signUpLoader, setSignUpLoader] = useState(false);
  const [inviteData, setInviteData] = useState(null);
  //const [stakeHolderData, setStakeHolderData] = useState(null);

  const navigate = useNavigate();

  const { invitationcode = null } = useParams();

  const handleCloseLoginForm = () => setOpenLoginForm(false);

  const handleCheckBoxChange = (setValues, values, name, value) => {
    setValues({ ...values, checks: { ...values.checks, [name]: value } });
  };

  const handleParticipantSignUp = async (values, actions) => {
    try {
      setSignUpLoader(true);
      let body = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        confirmEmail: values.email,
        password: values.password,
        confirmPassword: values.rePassword,
        phoneNo: values.phone,
        fundRaisingId: inviteData?.fundRaisingId,
        groupId: inviteData?.groupId,
        adminId: inviteData ? inviteData?.adminId : undefined,
        verificationSelection: "phone",
        invitationCode: inviteData ? invitationcode : undefined,
        isGuardian: values.checks.isGuardian,
        guardianEmail: values.checks.isGuardian ? values.email : undefined,
      };
      let response = await participantSignUp(body);
      Toaster("success", response.message);

      navigate("/auth/verify", {
        state: {
          verificationSelection: "phone",
          email: values?.email,
          role: "participant",
          mode: "signUp",
          phone: values?.phone,
          redirect: "/participant/dashboard",
        },
      });
      actions.setValues({
        firstName: "",
        lastName: "",
        email: "",
        reEmail: "",
        phone: "",
        password: "",
        rePassword: "",
        schools: "",
        fundraiser: null,
        checked: [],
      });

      setSignUpLoader(false);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Participant could not sign up";
      Toaster("error", errorMessage);
      setSignUpLoader(false);
    }
  };

  const handleLogin = async (values) => {
    try {
      let response = await logIn(
        values.email,
        values.password,
        "participant",
        "phone"
      );
      Toaster("success", response.message);
      navigate("/auth/verify", {
        state: {
          verificationSelection: "phone",
          email: values?.email,
          role: "participant",
          mode: "logIn",
          phone: response.data?.phoneNo,
          redirect: "/participant/dashboard",
        },
      });
    } catch (error) {
      let message = error.response?.data?.message || "Unable to send otp code";
      Toaster("error", message);
      throw new Error(message);
    }
  };

  const handleGetInvitationData = async (code) => {
    try {
      let response = await getInvitationCodeData("participant", code);
      let data = response.data;
      //console.log(data);
      //   let tempStakeHolders = [...stakeHolderData];
      //   tempStakeHolders[0].para = data?.adminName || "NA";
      //   tempStakeHolders[1].para = data?.fundRaisingName || "NA";

      //   setStakeHolderData(tempStakeHolders);
      setInviteData(data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Unable to get Invitation Data";
      Toaster("error", errorMessage);
    }
  };

  useEffect(() => {
    handleGetInvitationData(invitationcode);
  }, [invitationcode]);

  return (
    <div>
      {inviteData ? (
        <div className="participant-signup-wrapper">
          <div className="upper-section">
            <h1 className="heading">Participant Signup</h1>
            {/* {stakeHolderData && (
        <div className="menu">
          {stakeHolderData.map(({ Icon, title, para }) => (
            <div className="card">
              <Icon className="icon" />
              <h2 className="header">{title}</h2>
              <p className="paragraph">{para}</p>
            </div>
          ))}
        </div>
      )} */}
          </div>
          <div className="lower-section">
            <div className="existing-button">
              <CustomButton
                title={"I have an existing account"}
                onClick={() => setOpenLoginForm(true)}
              />
            </div>

            <Formik
              initialValues={{
                firstName: inviteData.firstName,
                lastName: inviteData.lastName,
                email: inviteData.email,
                reEmail: "",
                phone: inviteData.phoneNumber,
                password: "",
                rePassword: "",
                schools: "",
                fundraiser: inviteData.fundRaisingId || inviteData.groupId,
                checks: {
                  isGuardian: false,
                  isPrivacyPolicy: false,
                  isTerms: false,
                },
              }}
              validationSchema={participantsSignUpByLinkValidation}
              validateOnChange={true}
              validateOnBlur={false}
              enableReinitialize={true}
              onSubmit={(values, actions) =>
                handleParticipantSignUp(values, actions)
              }
            >
              {({ errors, setValues, values }) => (
                <Form>
                  <div className="signup-form">
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6} md={4}>
                        <CustomInput
                          label={"First Name"}
                          type={"text"}
                          name="firstName"
                          placeholder="John"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CustomInput
                          label={"Last Name"}
                          type={"text"}
                          name="lastName"
                          placeholder="Wirk"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CustomInput
                          label={
                            inviteData?.fundRaisingId
                              ? "Fundraiser Id"
                              : "Group Id"
                          }
                          type={"text"}
                          disabled
                          name="fundraiser"
                          placeholder="Please select fundraiser"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CustomInput
                          type="email"
                          label={"Email"}
                          name="email"
                          placeholder="johnwirk@gmail.com"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CustomInput
                          label={"Phone"}
                          type={"text"}
                          name="phone"
                          placeholder="+11234567890"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CustomInput
                          type="password"
                          label={"Password"}
                          name="password"
                          placeholder="Please enter your password"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CustomInput
                          type="password"
                          label={"Re-enter password"}
                          name="rePassword"
                          placeholder="Please re-enter your password"
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="condition-list">
                    <FormGroup>
                      <FormControlLabel
                        typeof="checkbox"
                        checked={values.checks?.isGuardian}
                        onChange={(event) =>
                          handleCheckBoxChange(
                            setValues,
                            values,
                            "isGuardian",
                            event.target.checked
                          )
                        }
                        control={<CustomCheckBox />}
                        label={
                          <p className="para">
                            I am a parent or gaurdian of the participant
                          </p>
                        }
                      />
                      <FormControlLabel
                        typeof="checkbox"
                        required
                        checked={values.checks?.isTerms}
                        onChange={(event) =>
                          handleCheckBoxChange(
                            setValues,
                            values,
                            "isTerms",
                            event.target.checked
                          )
                        }
                        control={<CustomCheckBox />}
                        label={
                          <p className="para">
                            I have read & agree to
                            <span className="link">Terms and condition</span>
                          </p>
                        }
                      />
                      <FormControlLabel
                        typeof="checkbox"
                        checked={values.checks?.isPrivacyPolicy}
                        onChange={(event) =>
                          handleCheckBoxChange(
                            setValues,
                            values,
                            "isPrivacyPolicy",
                            event.target.checked
                          )
                        }
                        required
                        control={<CustomCheckBox />}
                        label={
                          <p className="para">
                            I have read & agree to
                            <span className="link">Privacy and policy</span>
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>
                  <div>
                    <ReCaptchaV2
                      theme="dark"
                      sitekey={"6LfHILklAAAAAEQP0zw8P2sWvkI-X2GJ-duOldlK"}
                      className="recaptcha-container"
                      size="normal"
                    />
                  </div>
                  {signUpLoader ? (
                    <Spinner />
                  ) : (
                    <CustomButton
                      title={"Submit"}
                      className={"submit-button"}
                      type={"submit"}
                      disabled={!inviteData || Object.keys(errors).length > 0}
                    />
                  )}
                </Form>
              )}
            </Formik>
          </div>
          <LoginModal
            title={"Participant Login"}
            open={openLoginForm}
            onClose={handleCloseLoginForm}
            onLogin={handleLogin}
          />
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ParticipantsSignUpByLink;
