import { Radio } from "@mui/material";
import React from "react";

const CustomRadioButton = ({ checked, title, className, ...props }) => {
  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className={`${className}`}
    >
      <Radio
        checked={Boolean(checked)}
        {...props}
        sx={{
          color: "#E9C451",
          "&.Mui-checked": {
            color: "#E9C451",
          },
          "& .MuiSvgIcon-root": {
            fontSize: 24,
          },
        }}
      />
      <div>{title}</div>
    </div>
  );
};

export default CustomRadioButton;
