import React, { useEffect, useState } from "react";
import { FaUserGraduate, FaSchool } from "react-icons/fa";
import { RiOrganizationChart } from "react-icons/ri";
import "./styles.scss";
import CustomButton from "../../common/CustomButton";
import CustomInput from "../../common/CustomInput";
import CustomSelect from "../../common/CustomSelect";
import { Grid } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomCheckBox from "../../common/CustomCheckBox";
import ReCaptchaV2 from "react-google-recaptcha";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { adminSignUp } from "../../../apis/Admin";
import Spinner from "../../common/Spinner";
import { Toaster } from "../../../constants";
import { adminSignUpValidation } from "../../../constants/validate";
import LoginModal from "./LoginModal";
import { getInvitationCodeData, logIn } from "../../../apis/Auth";
import CustomPhone from "../../common/CustomPhone";
import { getAllFundraisersBasics } from "../../../apis/Fundraiser";

const AdminSignUp = () => {
  const stakeholders = [
    {
      title: "Primary Admin",
      Icon: FaUserGraduate,
      para: "None",
    },
    {
      title: "School",
      Icon: FaSchool,
      para: "will rogers high school",
    },
    {
      title: "Organization",
      Icon: RiOrganizationChart,
      para: "will rogers Marging Bands",
    },
  ];
  const [openLoginForm, setOpenLoginForm] = useState(false);

  const navigate = useNavigate();
  const handleCloseLoginForm = () => setOpenLoginForm(false);
  const [signUpLoader, setSignUpLoader] = useState(false);
  const [admin, setAdmin] = useState(null);
  const [fundraisers, setFundraisers] = useState([]);

  const { invitationCode = null } = useParams();

  const handleAdminSignUp = async (values, actions) => {
    try {
      setSignUpLoader(true);

      let body = {
        ...values,
        verificationSelection: "phone",
        invitationCode,
      };
      let response = await adminSignUp(body);

      Toaster(
        "success",
        response?.message || "An OTP code has been sent to you"
      );

      navigate("/auth/verify", {
        state: {
          verificationSelection: "phone",
          email: values.email,
          role: "secondaryAdmin",
          mode: "signUp",
          phone: values.phoneNo,
          redirect: "/admin/dashboard",
        },
      });
      actions.setValues({
        firstName: "",
        lastName: "",
        role: "",
        email: "",
        reEmail: "",
        phoneNo: "",
        password: "",
        rePassword: "",
        checks: {
          isPrivacyPolicy: false,
          isTerms: false,
        },
      });

      setSignUpLoader(false);
    } catch (error) {
      let errorMessage =
        error.response?.data?.message || "OTP code could not be sent";
      Toaster("error", errorMessage);
      setSignUpLoader(false);
    }
  };

  const handleLogin = async (values) => {
    try {
      let response = await logIn(
        values.email,
        values.password,
        "secondaryAdmin",
        "phone"
      );

      Toaster(
        "success",
        response?.message || "An OTP code has been sent to you"
      );

      navigate("/auth/verify", {
        state: {
          verificationSelection: "phone",
          email: values?.email,
          role: "secondaryAdmin",
          mode: "logIn",
          phone: response?.data?.phoneNo,
          redirect: "/admin/dashboard",
        },
      });
    } catch (error) {
      let errorMessage =
        error.response?.data?.message || "Admin could not login";
      Toaster("error", errorMessage);
      // setLoginLoader(false);
    }
  };

  const handleCheckBoxChange = (setValues, values, name, value) => {
    setValues({ ...values, checks: { ...values.checks, [name]: value } });
  };

  const handleInvitationData = async (code) => {
    try {
      let response = await getInvitationCodeData("secondaryAdmin", code);
      setAdmin(response.data);
    } catch (error) {
      let errorMessage =
        error.response?.data?.message ||
        "Invitation Code could not be verified";
      Toaster("error", errorMessage);
    }
  };

  useEffect(() => {
    if (invitationCode) {
      handleInvitationData(invitationCode);
    }
  }, [invitationCode]);

  const handleGetAllFundraisers = async () => {
    try {
      let response = await getAllFundraisersBasics();
      let data = response;
      let tempArray =
        data.length === 0
          ? []
          : data.map((item) => {
              return { value: item._id, label: item.fundRaisingName };
            });
      setFundraisers(tempArray);
    } catch (error) {
      error = error.response?.data?.message || "Unable to get Fudraisers";
      Toaster("error", error);
    }
  };

  useEffect(() => {
    if (invitationCode) handleGetAllFundraisers();
  }, [invitationCode]);

  return (
    <div className="admin-signup-wrapper">
      <div className="upper-section">
        <h1 className="heading">Fundraiser Administrator Signup</h1>
        <p className="para">
          If you are trying to sign up for an existing fundraiser please contact
          the Fundraiser Administrator for the sign up link after completing
          signup.{" "}
        </p>
      </div>
      <div className="lower-section">
        <div className="existing-button">
          <CustomButton
            title={"I have an existing account"}
            onClick={() => setOpenLoginForm(true)}
          />
        </div>
        <Formik
          initialValues={{
            firstName: admin ? admin?.firstName : "",
            lastName: admin ? admin?.lastName : "",
            email: admin ? admin?.email : "",
            reEmail: admin ? admin?.email : "",
            phoneNo: admin ? admin?.phoneNo : "",
            fundRaiserId: admin
              ? admin?.fundRaiserId
              : fundraisers.length > 0
              ? fundraisers[0]?.value
              : null,
            password: "",
            rePassword: "",
            checks: {
              isPrivacyPolicy: false,
              isTerms: false,
            },
          }}
          enableReinitialize
          validationSchema={adminSignUpValidation}
          validateOnChange={true}
          validateOnBlur={false}
          onSubmit={(values, actions) => handleAdminSignUp(values, actions)}
        >
          {({ setValues, values, errors }) => (
            <Form>
              <div className="signup-form">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      label={"First Name"}
                      type={"text"}
                      name="firstName"
                      placeholder="John"
                      disabled={admin}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      label={"Last Name"}
                      type={"text"}
                      name="lastName"
                      placeholder="Wirk"
                      disabled={admin}
                    />
                  </Grid>
                  {invitationCode && (
                    <Grid item xs={12} sm={6} md={4}>
                      <CustomSelect
                        type="text"
                        label={"Invitation Fundraiser"}
                        name="fundRaiserId"
                        options={fundraisers}
                        onChange={(e) =>
                          setValues({ ...values, fundRaiserId: e.target.value })
                        }
                        disabled={admin}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      type="email"
                      label={"Email"}
                      name="email"
                      placeholder="johnwirk@gmail.com"
                      disabled={admin}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      type="email"
                      label={"Re-enter email"}
                      name="reEmail"
                      placeholder="johnwirk@gmail.com"
                      disabled={admin}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomPhone
                      label={"Phone Number"}
                      name="phoneNo"
                      placeholder={"+11234567890"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      type="password"
                      label={"Password"}
                      name="password"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      type="password"
                      label={"Re-enter password"}
                      name="rePassword"
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="condition-list">
                <FormGroup>
                  <FormControlLabel
                    checked={values.checks.isPrivacyPolicy}
                    onChange={(event) =>
                      handleCheckBoxChange(
                        setValues,
                        values,
                        "isPrivacyPolicy",
                        event.target.checked
                      )
                    }
                    typeof="checkbox"
                    required
                    control={<CustomCheckBox />}
                    label={
                      <p className="para">
                        I have read & agree to
                        <span className="link">Terms and condition</span>
                      </p>
                    }
                  />
                  <FormControlLabel
                    checked={values.checks.isTerms}
                    onChange={(event) =>
                      handleCheckBoxChange(
                        setValues,
                        values,
                        "isTerms",
                        event.target.checked
                      )
                    }
                    typeof="checkbox"
                    required
                    control={<CustomCheckBox />}
                    label={
                      <p className="para">
                        I have read & agree to
                        <span className="link">Privacy and policy</span>
                      </p>
                    }
                  />
                </FormGroup>
              </div>
              <div>
                <ReCaptchaV2
                  theme="dark"
                  sitekey={"6LfHILklAAAAAEQP0zw8P2sWvkI-X2GJ-duOldlK"}
                  className="recaptcha-container"
                  size="normal"
                />
              </div>
              {signUpLoader ? (
                <Spinner />
              ) : (
                <CustomButton
                  title={"Submit"}
                  className={"submit-button"}
                  type={"submit"}
                  disabled={Object.keys(errors).length > 0}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>

      <LoginModal
        open={openLoginForm}
        onClose={handleCloseLoginForm}
        onLogin={handleLogin}
      />
    </div>
  );
};

export default AdminSignUp;
