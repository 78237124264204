import React from "react";
import CustomButton from "../../common/CustomButton";
import { useNavigate } from "react-router-dom";

const AccountTypeSelection = ({ pageType }) => {
  const navigate = useNavigate();

  const adminRedirectRoute =
    pageType === "login" ? "/admin/login" : "/admin/signup";
  const participantRedirectRoute =
    pageType === "login" ? "/participant/login" : "/participant/signup";

  const teammemberReirectRoute = "/teammember/login";

  return (
    <div className="account-type-selection-wrapper">
      <div className="title">Type of account</div>
      <CustomButton
        onClick={() => navigate(adminRedirectRoute)}
        className={"custom-btn"}
        title={"Administrator"}
      />
      <div className="text">Create and manage fundraisers</div>
      <CustomButton
        onClick={() => navigate(participantRedirectRoute)}
        className={"custom-btn"}
        title={"Participant"}
      />
      <div className="text">Join existing fundraiser</div>
      {pageType === "login" && (
        <React.Fragment>
          <CustomButton
            onClick={() => navigate(teammemberReirectRoute)}
            className={"custom-btn"}
            title={"Teammember"}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default AccountTypeSelection;
