import React from "react";
import { AiOutlineUser } from "react-icons/ai";

const UserAccount = ({ className, ...rest }) => {
  return (
    <div className={`user-account-wrapper ${className}`} {...rest}>
      <AiOutlineUser className="icon" />
    </div>
  );
};

export default UserAccount;
