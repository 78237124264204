import React, { useCallback, useEffect, useState } from "react";
import CustomDialog from "../../../common/CustomDialog";
import { Form, Formik } from "formik";
import CustomInput from "../../../common/CustomInput";
import { Grid } from "@mui/material";
import CustomButton from "../../../common/CustomButton";
import Spinner from "../../../common/Spinner";
import { newGroupValidationSchema } from "../../../../constants/validate";
import CustomSelect from "../../../common/CustomSelect";
import { GetSecondaryAdmins } from "../../../../apis/Admin";
import { useSelector } from "react-redux";

const NewGroupModal = ({
  open,
  onClose,
  onSubmit,
  loader,
  groupGoalCapacity = 0,
  title = "",
}) => {
  const handleSubmit = (values) => {
    if (values.groupTarget > groupGoalCapacity) return;
    let data = { newGroupData: values };
    const adminEmail = values.adminEmail;
    let groupAdmin;
    if (!adminEmail)
      groupAdmin = admins.find((admin) => admin.email === adminList[0].value);
    else groupAdmin = admins.find((admin) => admin.email === adminEmail);
    if (groupAdmin) {
      data.admin = groupAdmin;
    }

    onSubmit(data);
  };

  const [adminList, setAdminList] = useState(null);

  const [admins, setAdmins] = useState(null);

  const auth = useSelector((state) => state.auth.me);

  const prioritizeEmail = (adminEmails, email) => {
    if (!email) return adminEmails;

    const index = adminEmails.findIndex((admin) => admin.value === email);

    if (index === -1) return adminEmails;

    const sortedAdminEmails = [
      adminEmails[index],
      ...adminEmails.slice(0, index),
      ...adminEmails.slice(index + 1),
    ];

    return sortedAdminEmails;
  };

  const fetchAdminList = useCallback(async () => {
    const admins = await GetSecondaryAdmins();
    setAdmins(admins);
    const adminEmails = admins.map((admin) => {
      return {
        label:
          admin.firstName + " " + admin.lastName + " (" + admin.email + ")",
        value: admin.email,
      };
    });
    const sortedEmails = prioritizeEmail(adminEmails, auth?.email);
    setAdminList(sortedEmails);
  }, [auth]);

  useEffect(() => {
    if(open && !adminList)
    fetchAdminList();
  }, [fetchAdminList,open]);

  return (
    <CustomDialog open={open} onClose={onClose} title={title}>
      <Formik
        initialValues={{
          groupName: "",
          adminEmail: "",
          groupTarget: 0,
          groupCause: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={newGroupValidationSchema}
        validateOnChange={true}
      >
        {({ handleSubmit, errors, values, setValues }) => (
          <Form>
            <div className="admin-group-modal-inner-wrapper">
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  <CustomInput
                    name="groupName"
                    label={"Group Name"}
                    placeholder="Group xyz"
                    className={"input"}
                  />
                </Grid>
                <Grid item sm={12}>
                  <CustomSelect
                    name="adminEmail"
                    label={"Admin Email"}
                    placeholder="admin@gmail.com"
                    options={adminList || []}
                    onChange={(e) => {
                      setValues({ ...values, adminEmail: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <CustomInput
                    name="groupCause"
                    label={"Group Cause"}
                    placeholder="To provide musical instruments etc."
                    className={"input"}
                  />
                </Grid>
                <Grid item sm={12}>
                  <CustomInput
                    name="groupTarget"
                    label={"Group Goal"}
                    placeholder="$10,000"
                    type={"number"}
                    step={"100"}
                    min={"0"}
                    max={groupGoalCapacity}
                  />
                  <div className="warning">
                    {groupGoalCapacity === 0
                      ? "Increase fundraiser goal to accomodate group goal"
                      : `You can set group goal to at max `}
                    <strong>${groupGoalCapacity}</strong>
                  </div>
                </Grid>
              </Grid>
              <div className="submit-button">
                {loader ? (
                  <Spinner />
                ) : (
                  <CustomButton
                    title={"Confirm"}
                    className={"submit"}
                    onClick={handleSubmit}
                    disabled={Object.keys(errors).length > 0}
                  />
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};

export default NewGroupModal;
