import axios from "../AxiosInstance";

export const addSponsor = (sponsorData) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/sponsorRoutes/addSponsor`, sponsorData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getSponsor = (fundRaisingId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/sponsorRoutes/getSponsor`, {
        params: {
          fundRaisingId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateSponsor = (sponsorId, isFeatured) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/sponsorRoutes/updateSponsor`, { sponsorId, isFeatured })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
