import React from "react";
import GameImg1 from "../../../assets/gameImg1.png";
import GameImg2 from "../../../assets/gameImg2.png";
import GameImg3 from "../../../assets/gameImg3.png";
import GameImg4 from "../../../assets/gameImg4.png";

function Game() {
  return (
    <div className="game-main-wrapper">
      <div className="cards-wrapper">
        <div className="card-img-wrapper">
          <img src={GameImg1} className="card-img" />
        </div>
        <div className="card-para-wrapper">
          <p>
            <h2 className="game-title">Hang Glider</h2>
            Tap the skeen to keep your handglide afloat as you navigate between
            airplanes and hot air balloons
          </p>
        </div>
      </div>

      <div className="cards-wrapper2">
        <div className="card-para-wrapper2">
          <p>
          <h2 className="game-title">Alphabet Merge</h2>
            Merge the letters of the alphabet together to make it all the way
            from A to Z
          </p>
        </div>
        <div className="card-img-wrapper2">
          <img src={GameImg2} className="card-img" />
        </div>
      </div>

      <div className="cards-wrapper">
        <div className="card-img-wrapper">
          <img src={GameImg3} className="card-img" />
        </div>
        <div className="card-para-wrapper">
          <p>
          <h2 className="game-title">Freight train</h2>
            Steer your train to pick up as many cars as you can and avoid
            unloading them.
          </p>
        </div>
      </div>

      <div className="cards-wrapper2">
        <div className="card-para-wrapper2">
          <p>
          <h2 className="game-title">Gem Crusher</h2>
            Shoot gem of different shapes and colors out of a canon. if you
            match the shape and colors the gems are destroyed
          </p>
        </div>
        <div className="card-img-wrapper2">
          <img src={GameImg4} className="card-img" />
        </div>
      </div>
    </div>
  );
}

export default Game;
