import axios from "../AxiosInstance";

export const verifyAccount = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post("stripeRoutes/verifyStripeAccount", body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const createAccount = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post("stripeRoutes/createStripeAccount", body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getAllAccounts = () =>
  new Promise((resolve, reject) => {
    axios
      .get("stripeRoutes/getAllAccounts")
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const generateAccountLink = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post("stripeRoutes/generateAccountLink", body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const deleteAccount = (id) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`stripeRoutes/deleteStripeAccount?stripeId=${id}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
