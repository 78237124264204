import axios from "../AxiosInstance";

export const createGame = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/gameRoutes/createGame`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateGame = (body) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/gameRoutes/updateGame`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const deleteGame = (gameId) =>
  new Promise((resolve, reject) => {
    axios
      .delete("/gameRoutes/deleteGame?gameId=" + gameId)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

//   export const deleteFundraiser = (fundRaisingId) =>
//     new Promise((resolve, reject) => {
//       axios
//         .delete(
//           `/fundRaisingRoutes/deleteFundRaising?fundRaisingId=${fundRaisingId}`
//         )
//         .then((response) => {
//           resolve(response.data);
//         })
//         .catch((err) => {
//           reject(err);
//         });
//     });
