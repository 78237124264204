import React, { useEffect, useState } from "react";
import CustomButton from "../../common/CustomButton";
import { findMessage, Toaster } from "../../../constants";
import { useSelector } from "react-redux";
import FundraiserSelectorModal from "../../pages/Admin/Dashboard/FundraiserSelectorModal";
import {
  getParticipantFundraisings,
  requestToJoinNewFundRaising,
} from "../../../apis/Participants";
import { getAllFundraisers } from "../../../apis/Fundraiser";
import { useNavigate, useParams } from "react-router-dom";

const ParticipantDashboardV2 = () => {
  const [joinFundraiserModal, setJoinFundraiserModal] = useState(false);
  const [previousFundraiserModal, setPreviousFundraiserModal] = useState(false);

  const [participantFundraisers, setParticipantFundraisers] = useState(null);

  const [fundraisers, setFundraisers] = useState(null);

  const [loader, setLoader] = useState(false);

  const auth = useSelector((state) => state.auth.me);

  const { fundraiserId = null } = useParams();

  const fetchAllFundraisers = async () => {
    if (!participantFundraisers) return;
    const allFundraisers = await getAllFundraisers();

    const participantFundraisersIds = new Set(
      participantFundraisers.map((fundraiser) => fundraiser._id)
    );

    const unjoinedFundraisers = allFundraisers.filter(
      (fundraiser) => !participantFundraisersIds.has(fundraiser._id)
    );

    setFundraisers(unjoinedFundraisers);
  };

  const fetchParticipantData = async () => {
    const data = await getParticipantFundraisings();
    setParticipantFundraisers(data);
  };

  const handleJoinFundraiser = async (fundraiserId) => {
    setLoader(true);
    try {
      await requestToJoinNewFundRaising(fundraiserId);
      setLoader(false);
      onModalClose();
      Toaster("success", "Request submitted, Wait for the admin to approve it");
    } catch (e) {
      setLoader(false);
      Toaster("error", findMessage(e, "Error requesting to join fundraiser!"));
    }
  };

  const onModalClose = () => {
    setJoinFundraiserModal(false);
    setPreviousFundraiserModal(false);
  };

  useEffect(
    () => {
      fetchParticipantData();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    fetchAllFundraisers();
  }, [participantFundraisers]);

  const navigate = useNavigate();

  return (
    <div className="admin-dashboard-wrapper">
      {/* modals */}
      <FundraiserSelectorModal
        open={previousFundraiserModal}
        onClose={onModalClose}
        fundraiserData={participantFundraisers || []}
        onSubmit={(fundraiserId) =>
          navigate(`/participant/dashboard/${fundraiserId}`)
        }
        loader={loader}
      />
      <FundraiserSelectorModal
        open={joinFundraiserModal}
        onClose={onModalClose}
        fundraiserData={fundraisers || []}
        onSubmit={(fundraiserId) => handleJoinFundraiser(fundraiserId)}
        loader={loader}
      />
      <div className="title">Participant Dashboard</div>

      <div className="stats-wrapper-centered">
        <div className="stat-one-wrapper section">
          <div className="stat-title">Information</div>
          <div className="stat">
            Name:
            <span>{auth?.firstName || "---"}</span>
            <span>{auth?.lastName || "---"}</span>
          </div>
          <div className="stat">
            Email: <span>{auth?.email || "---"}</span>
          </div>
          <div className="stat">
            Contact: <span>{auth?.phoneNo || "---"}</span>
          </div>
        </div>
      </div>
      <div className="secondary-admin-buttons">
        <CustomButton
          className="customBtn"
          title="Join Fundraiser"
          disabled={!fundraisers}
          onClick={() => {
            if (fundraisers.length === 0)
              Toaster("warning", "No fundraisers to join!");
            else setJoinFundraiserModal(true);
          }}
        />
        <CustomButton
          className="customBtn"
          title="Previous Fundraiser Information"
          disabled={!participantFundraisers}
          onClick={() => {
            if (participantFundraisers?.length === 0)
              return Toaster(
                "warning",
                "You do not have any previous fundraisers!"
              );
            setPreviousFundraiserModal(true);
          }}
        />
      </div>
    </div>
  );
};

export default ParticipantDashboardV2;
