import React, { useEffect, useState } from "react";
import CustomButton from "../../common/CustomButton";
import { AiFillCheckCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { resendEmail, verifyEmail } from "../../../apis/Auth";
import Spinner from "../../common/Spinner";
import { GiCancel } from "react-icons/gi";

const EmailVerify = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(false);

  const handleEmailVerification = () => {
    setLoader(true);
    verifyEmail(token)
      .then((response) => {
        setMessage(response.message);
        setLoader(false);
        setStatus(true);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        // //console.log(err.response.data.success);
        setLoader(false);
        setStatus(false);
      });
  };

  const handleSubmit = () => navigate("/signup");

  useEffect(() => {
    handleEmailVerification();
  }, []);

  if (loader) {
    return <Spinner />;
  }
  return (
    <div className="email-verification-wrapper">
      <div className="icon-wrapper">
        {status ? (
          <AiFillCheckCircle className="icon" />
        ) : (
          <GiCancel className="cancel-icon" />
        )}
      </div>
      <div className="paragraph">{message}</div>
      <CustomButton title={"Back to Fundraiser"} onClick={handleSubmit} />
    </div>
  );
};

export default EmailVerify;
