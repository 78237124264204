import { useCallback, useEffect, useState } from "react";
import {
  createAccount,
  deleteAccount,
  generateAccountLink,
  getAllAccounts,
} from "../../../apis/Stripe";
import Spinner from "../../common/Spinner";
import CustomTable from "../../common/CustomTable";
import CustomButton from "../../common/CustomButton";
import NewAccountModal from "./newAccountModal";
import DeleteWarningModal from "./deleteWarningModal";
import { Toaster } from "../../../constants";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../common/ConfirmationModal";

const StripeAccounts = () => {
  const [stripeAccounts, setStripeAccounts] = useState(null);

  const [selectedAccount, selectAccount] = useState(null);

  const [loader, setLoader] = useState(false);

  const [generateLoader, setGenerateLoader] = useState(false);

  const [activeModal, setActiveModal] = useState(null);

  const navigate = useNavigate();

  const onModalClose = () => {
    setActiveModal(null);
  };

  const fetchStripeAccounts = useCallback(async () => {
    const accounts = await getAllAccounts();
    setStripeAccounts(accounts);
  }, []);

  useEffect(() => {
    fetchStripeAccounts();
  }, []);

  const generateStripeRedirectLink = async () => {
    setGenerateLoader(true);
    try {
      const body = {
        accountId: stripeAccounts[selectedAccount].accountId,
        refreshUrl: window.location.href,
        returnUrl: window.location.href,
      };
      const link = await generateAccountLink(body);
      setGenerateLoader(false);
      Toaster("success", "Link Generated Successfully! Redirecting...");
      setTimeout(() => {
        window.location.href = link;
      }, 2000);
    } catch (e) {
      Toaster(
        "error",
        e.message || "Error generating link! Try again later..."
      );
      setGenerateLoader(false);
    }
  };

  const handleDeleteAccount = async (id) => {
    setLoader(true);
    try {
      await deleteAccount(id);
      await fetchStripeAccounts();
      setLoader(false);
      setActiveModal(false);
      Toaster("success", "Account deleted Successfully!");
    } catch (e) {
      setLoader(false);
      setActiveModal(false);
      const warningData = e?.response?.data?.data;
      if (warningData) {
        setActiveModal({ type: "DELETE_WARNING", data: warningData });
      } else Toaster("error", e?.message || "Error deleting account!");
    }
  };

  const accountTableCols = [
    { name: "Name", dataIndex: "name" },
    { name: "Id", dataIndex: "accountId" },
    { name: "Linked", dataIndex: "isVerified" },
  ];

  const accountTableRows = stripeAccounts || [];

  if (!stripeAccounts) return <Spinner />;
  const createNewAccount = async (data) => {
    setLoader(true);
    try {
      const body = data;
      await createAccount(body);
      await fetchStripeAccounts();
      onModalClose();
      Toaster("success", "Account created successfully!");
      setLoader(false);
    } catch (e) {
      setLoader(false);
      Toaster("error", e?.message || "Error creating new account!");
    }
  };

  return (
    <div>
      <CustomButton
        title={"< Back to dashboard"}
        onClick={() => navigate("/admin/dashboard")}
        className={"navigate-button"}
      />
      <div
        className="navigate-text"
        onClick={() => navigate("/admin/dashboard")}
      >
        {"< Back to dashboard"}
      </div>
      <div className="stripe-accounts-wrapper">
        <div className="title">My Accounts</div>
        <NewAccountModal
          open={activeModal?.type === "NEW_ACCOUNT_MODAL"}
          onClose={onModalClose}
          loader={loader}
          onSubmit={(values) => createNewAccount(values)}
        />
        <ConfirmationModal
          title={"Confirm Delete Account"}
          prompt={`Are you sure you want to delete account "${
            activeModal?.accountName || ""
          }"? This action can not be undone!`}
          open={activeModal?.type === "DELETE_ACCOUNT"}
          onClose={onModalClose}
          onConfirmation={() => handleDeleteAccount(activeModal?.accountId)}
          warningOnConfirm
          loader={loader}
        />
        <DeleteWarningModal
          open={activeModal?.type === "DELETE_WARNING"}
          onClose={onModalClose}
          data={activeModal?.data}
        />
        <div className="table-wrapper">
          <CustomTable
            rows={accountTableRows}
            columns={accountTableCols}
            emptyDisplayText={"No accounts present! Create a new one"}
            isSelection
            onSelect={(index) => selectAccount(index)}
            maxHeight={400}
            isActions
            isEdit={false}
            onDelete={(data) => {
              setActiveModal({
                type: "DELETE_ACCOUNT",
                accountName: data.name,
                accountId: data.accountId,
              });
            }}
            loader={loader}
          />
        </div>
        <div className="button-group">
          <CustomButton
            title={"Create New Account"}
            onClick={() => setActiveModal({ type: "NEW_ACCOUNT_MODAL" })}
          />
          {generateLoader ? (
            <Spinner />
          ) : (
            <CustomButton
              title={
                stripeAccounts[selectedAccount]?.isVerified
                  ? "Update Stripe Account"
                  : "Link Stripe Account"
              }
              disabled={selectedAccount === null}
              onClick={generateStripeRedirectLink}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StripeAccounts;
