import React, { useCallback, useEffect, useState } from "react";
import ".././styles.scss";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "../../../../common/CustomInput";
import { Form, Formik } from "formik";
import CustomButton from "../../../../common/CustomButton";
import { GroupsValidationSchema } from "../../../../../constants/validate";
import CustomDialog from "../../../../common/CustomDialog";
import { GetSecondaryAdmins } from "../../../../../apis/Admin";
import CustomSelect from "../../../../common/CustomSelect";
import { useSelector } from "react-redux";
import Spinner from "../../../../common/Spinner";

const GroupsModal = ({
  fundraiserData = null,
  onSubmit,
  loader,
  updateFundraiserData,
  open,
  onClose,
  title,
  hideSkip = false,
}) => {
  const emptyGroup = {
    name: "",
    admin: "",
    groupTarget: null,
    groupCause: null,
    stripe: null,
  };

  const initialValue =
    !fundraiserData?.groups || fundraiserData.groups.length === 0
      ? [{ ...emptyGroup }, { ...emptyGroup }]
      : fundraiserData.groups;

  const [adminList, setAdminList] = useState(null);

  const auth = useSelector((state) => state.auth.me);

  const prioritizeEmail = (adminEmails, email) => {
    if (!email) return adminEmails;

    const index = adminEmails.findIndex((admin) => admin.value === email);

    if (index === -1) return adminEmails;

    const sortedAdminEmails = [
      adminEmails[index],
      ...adminEmails.slice(0, index),
      ...adminEmails.slice(index + 1),
    ];

    return sortedAdminEmails;
  };

  const fetchAdminList = useCallback(async () => {
    const admins = await GetSecondaryAdmins();
    const adminEmails = admins.map((admin) => {
      return {
        label:
          admin.firstName + " " + admin.lastName + " (" + admin.email + ")",
        value: admin.email,
      };
    });
    const sortedEmails = prioritizeEmail(adminEmails, auth?.email);
    setAdminList(sortedEmails);
  }, [auth]);

  useEffect(() => {
    if(open && !adminList)
    fetchAdminList();
  }, [fetchAdminList,open]);

  const handleSubmit = (values) => {
    updateFundraiserData &&
      updateFundraiserData({ ...fundraiserData, groups: values.groups });
    onSubmit(values.groups);
  };

  const handleSkip = () => {
    onSubmit();
  };

  const isNonUniqueName = (values, index) => {
    for (let i = 0; i < values.groups.length; i += 1) {
      if (i !== index && values.groups[index].name === values.groups[i].name)
        return true;
    }
    return false;
  };

  // const isValidAdmin = (values, index) => {
  //   if (!adminList || !values.groups[index].admin) return true;
  //   for (let i = 0; i < adminList.length; i += 1) {
  //     if (adminList[i] === values.groups[index].admin) return true;
  //   }
  //   return false;
  // };

  return (
    <CustomDialog open={open} onClose={onClose} title={title}>
      <div className="groups-modal">
        <div className="para">
          Groups are used to add competition to the fundraiser. Groups may be
          used within the same organization or to compete against another
          organization.
        </div>
        <Formik
          initialValues={{ groups: initialValue }}
          validationSchema={GroupsValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, setFieldValue, errors, values, setValues }) => (
            <Form>
              {values.groups.map((group, index) => (
                <div className="group">
                  <div className="heading">Group {index + 1}:</div>
                  <div className="group-internal-wrapper">
                    <div className="group-form">
                      <CustomInput
                        className={"custom-input"}
                        name={`groups.${index}.name`}
                        label={"Group Name"}
                        placeholder="Enter group name"
                        value={group.name}
                      ></CustomInput>
                      {errors.groups &&
                        typeof errors.groups === "string" &&
                        isNonUniqueName(values, index) && (
                          <div className="error">{errors.groups}</div>
                        )}
                      {/* <CustomInput
                        className={"custom-input"}
                        name={`groups.${index}.admin`}
                        label={"Group Admin"}
                        placeholder="Select group admin"
                        value={group.admin}
                      ></CustomInput> */}
                      <CustomSelect
                        type="text"
                        label={"Group Admin"}
                        name={`groups.${index}.admin`}
                        options={adminList || []}
                        onChange={(e) => {
                          const updatedValues = values;
                          updatedValues.groups[index].admin = e.target.value;
                          setValues(updatedValues);
                        }}
                      />
                      {/* {!isValidAdmin(values, index) && (
                        <div className="error">Invalid Admin Email</div>
                      )} */}
                      <div className="info">
                        Default Group Admin is account holder. The Group Admins
                        may be changed later.
                      </div>
                    </div>
                    {index !== 0 && (
                      <CustomButton
                        className={"group-delete-button"}
                        title={"Delete"}
                        onClick={(event) => {
                          event.preventDefault();
                          setValues({
                            groups: values.groups.filter((_, i) => i !== index),
                          });
                        }}
                      ></CustomButton>
                    )}
                  </div>
                </div>
              ))}
              <div className="button-group">
                <CustomButton
                  className={"custom-button"}
                  title={"Add Group"}
                  onClick={(event) => {
                    event.preventDefault();
                    values.groups.push({ ...emptyGroup });
                    setValues(values);
                  }}
                ></CustomButton>
                {loader ? (
                  <Spinner />
                ) : (
                  <CustomButton
                    className={"custom-button"}
                    title={"Submit"}
                    onClick={handleSubmit}
                  ></CustomButton>
                )}
                {!hideSkip && (
                  <React.Fragment>
                    <CustomButton
                      className={"custom-button"}
                      title={"Skip"}
                      onClick={handleSkip}
                    ></CustomButton>
                    <div className="info">Groups may be created later.</div>
                  </React.Fragment>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
};

export default GroupsModal;
